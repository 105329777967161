import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {catchError, map, concatMap, withLatestFrom, filter, switchMap, mergeMap, share, delay} from 'rxjs/operators';
import {of } from 'rxjs';
import * as ClientActions from '../../actions/client-actions/client.actions';
import {ClientService} from "../../../services/clients/client.service";
import {Store} from "@ngrx/store";
import {State} from "../../../../../store";
import {NotificationService} from "../../../../../services/notification.service";
import {ClientNotesService} from "../../../clients/services/client-notes.service";
import {normalizeClientData, mapClientDataToClientGridData, mapClientDataToDropdown, normalizeClientGridData, mapSearchProps} from "../../../../../store/utils";
import {ResponseClientData} from "../../../clients/models";
import {
    clientsEntity,
    clientDropdownEntity,
    clientGridEntity,
    clientRatesEntity, clientNotesEntity, clientContactsEntity, clientFilesEntity,
    leadDropdownEntity
} from "../../reducers";
import {createFormData} from "../../../../../utills";
import _ from "lodash";
import {ClientStatusTypes} from "../../../clients/utils";
import {ResponseClientGridData} from "../../../clients/models/client.model";

@Injectable()
export class ClientEffects {
    clientGridSearch$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ClientActions.searchClientGrid),
            withLatestFrom(this.store.select(clientGridEntity.selectors.selectState)),
            filter(([action, clientGrid]) => !(JSON.stringify(clientGrid.criteria) === JSON.stringify(action)) || !clientGrid.firstLoad),
            switchMap(([action, clientGrid]) => {
                this.store.dispatch(clientGridEntity.actions.updateAdditional({updates:{isLoading: true}}))
                return this.clientService.search(action).pipe(
                    map(response => {
                        this.store.dispatch(clientGridEntity.actions.updateAdditional({updates: mapSearchProps(action, response.response.count)}))
                        const normalized = normalizeClientGridData(response.response.result);
                        this.store.dispatch(clientRatesEntity.actions.upsertMany({entities: normalized.rates}));
                        return clientGridEntity.actions.setAll({entities: normalized.gridData})
                    }),
                    catchError(error => of(clientGridEntity.actions.updateAdditional({updates: {isLoading: false, error: error}}))),
                )}
            )
        );
    });
    getClient$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ClientActions.getClient),
            mergeMap((action) => of(action).pipe(withLatestFrom(this.store.select(clientsEntity.selectors.selectById(action.id))))),
            filter(([action, client]) => !(client && !client?.loadedPartially)),
            switchMap(([action, client]) =>
                this.clientService.getClientById(action.id).pipe(
                    map(response => {
                        const normalized = normalizeClientData(response.response);
                        this.store.dispatch(clientNotesEntity.actions.upsertMany({entities: normalized.notes}));
                        this.store.dispatch(clientContactsEntity.actions.upsertMany({entities: normalized.contacts}));
                        this.store.dispatch(clientFilesEntity.actions.upsertMany({entities: normalized.files}));
                        this.store.dispatch(clientRatesEntity.actions.upsertMany({entities: normalized.rates}));
                        return clientsEntity.actions.upsertOne({entity: {...normalized.data, loadedPartially: false}})
                    }),
                    catchError(error => {
                        return of(clientsEntity.actions.updateAdditional({updates: {error}}));
                    })
                )
            ),
            share()
        );
    });
    getClientInfo$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ClientActions.getClientInfo),
            mergeMap((action) => of(action).pipe(withLatestFrom(this.store.select(clientsEntity.selectors.selectById(action.id))))),
            filter(([action, client]) => !client),
            switchMap(([action, client]) =>
                this.clientService.getClientInfoById(action.id).pipe(
                    map(response => {
                        response.response.clientRates = [];
                        return clientsEntity.actions.upsertOne({entity: {...response.response, loadedPartially: true}});
                    }),
                    catchError(error => {
                        return of(clientsEntity.actions.updateAdditional({updates: {error}}));
                    })
                )
            ),
            share()
        );
    });
    addClient$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ClientActions.addClient),
            concatMap((action) =>
                this.clientService.saveClient(action).pipe(
                    map(response => {
                        this.notificationService.showSuccess('Saved', 'Client Saved Successfully');
                        const normalized = normalizeClientData(response.response);
                        this.store.dispatch(clientContactsEntity.actions.upsertMany({entities: normalized.contacts}));
                        this.store.dispatch(clientDropdownEntity.actions.addOne({entity: mapClientDataToDropdown(response.response)}));
                        this.store.dispatch(leadDropdownEntity.actions.addOne({entity: mapClientDataToDropdown(response.response)}));
                        this.store.dispatch(clientGridEntity.actions.unshift({entities: [mapClientDataToClientGridData(response.response)]}));
                        this.store.dispatch(clientRatesEntity.actions.upsertMany({entities: normalized.rates}));
                        return clientsEntity.actions.addOne({entity: normalized.data})
                    }),
                    catchError(error => {
                        return of(clientsEntity.actions.updateAdditional({updates: {error}}));
                    })
                )
            ),
            share()
        );
    });
    updateClient$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ClientActions.updateClient),
            concatMap((action) =>
                this.clientService.updateClient(action).pipe(
                    map(response => {
                        this.notificationService.showSuccess('Saved', 'Client Saved Successfully');
                        const normalized = normalizeClientData(response.response);
                        this.store.dispatch(clientGridEntity.actions.updateOne({update: {id: response.response.id, changes: mapClientDataToClientGridData(response.response)}}));
                        this.store.dispatch(clientDropdownEntity.actions.updateOne({update: {id: response.response.id, changes: mapClientDataToDropdown(response.response)}}));
                        this.store.dispatch(leadDropdownEntity.actions.updateOne({update: {id: response.response.id, changes: mapClientDataToDropdown(response.response)}}));
                        return clientsEntity.actions.updateOne({update: {id: normalized.data.id, changes: normalized.data}});
                    }),
                    catchError(error => {
                        return of(clientsEntity.actions.updateAdditional({updates: {error}}));
                    })
                )
            ),
            share()
        );
    });
    deleteClient$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ClientActions.deleteClient),
            concatMap((action) =>
                this.clientService.deleteClientByIds(action.ids).pipe(
                    map(data => {
                        this.notificationService.showSuccess('Deleted', 'Client Deleted Successfully');
                        this.store.dispatch(clientGridEntity.actions.removeMany(action));
                        this.store.dispatch(clientDropdownEntity.actions.removeMany(action));
                        this.store.dispatch(leadDropdownEntity.actions.removeMany(action));
                        return clientsEntity.actions.removeMany(action);
                    }),
                    catchError(error => {
                        return of(clientsEntity.actions.updateAdditional({updates: {error}}));
                    })
                )
            ),
            share()
        );
    });
    updateClientAvatar$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ClientActions.updateClientAvatar),
            concatMap((action) => {
               return this.clientService.updateAvatar(createFormData(_.cloneDeep(action))).pipe(
                    map(response => {
                        this.notificationService.showSuccess('Saved', 'Client Avatar Saved Successfully');
                        return clientsEntity.actions.mapOne({entityMap:{ id: response.response.id, map: (entity) => <ResponseClientData>{...entity, avatar: response.response.avatar} }})
                    }),
                    catchError(error => {
                        return of(clientsEntity.actions.updateAdditional({updates: {error}}));
                    })
                )}
            ),
            share()
        );
    });

    changeClientStatus$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ClientActions.changeClientStatus),
            concatMap((action) => {
                return this.clientService.changeStatus(action.clientIds, action.statusId).pipe(
                    map(response => {
                        this.notificationService.showSuccess('Saved', 'Client Status Changed Successfully');
                        if(action.statusId === ClientStatusTypes.DELETED) {
                            this.store.dispatch(clientDropdownEntity.actions.removeMany({ids: action.clientIds}));
                            this.store.dispatch(leadDropdownEntity.actions.removeMany({ids: action.clientIds}));
                        } else if(action?.clientsDropdown) this.store.dispatch(clientDropdownEntity.actions.upsertMany({entities: action.clientsDropdown}));
                        this.store.dispatch(leadDropdownEntity.actions.upsertMany({entities: action.clientsDropdown}));
                        this.store.dispatch(clientGridEntity.actions.mapMany({entityMap: (entity) => {
                            if(action.clientIds.includes(entity.id)) return <ResponseClientGridData>{...entity, clientStatusId: action.statusId}
                            else return entity;
                        }}));
                        return clientsEntity.actions.mapMany({entityMap: (entity) => {
                            if(action.clientIds.includes(entity.id)) return <ResponseClientData>{...entity, clientStatusId: action.statusId}
                            else return entity;
                        }});
                    }),
                    catchError(error => {
                        return of(clientsEntity.actions.updateAdditional({updates: {error}}));
                    })
                )}
            ),
            share()
        );
    });

    constructor(
        private actions$: Actions,
        private clientService: ClientService,
        private clientNoteService: ClientNotesService,
        private store: Store<State>,
        private notificationService: NotificationService,
    ) {}

}