import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { PostUser, RoleModel, UserModel } from 'app/main/admin/models';
import {
    PostSetUserStatusAndAvatar,
    ResponseUserData,
    ResponseUserGridData,
    UpdatePasswordModel,
    UserGridData,
    UserPreferencesModel,
    UserSearch,
    UserTeamMembership
} from 'app/main/admin/models/user.model';
import { BaseResultModel, PMDashboardModel, SearchResult } from 'app/models';
import { DropDownOptionsModel } from 'app/models/general';
import { DropDownItem } from 'app/models/general/dropdownItem.model';
import { ResponseProjectManagerData } from 'app/models/general/project-manager';
import { SalesPersonsModel } from 'app/models/general/salesPersons.model';
import { AppConfigService } from 'app/services/app.settings.service';
import { TpAuthService } from 'app/services/auth/tp-auth.service';
import { Constants } from 'app/shared.constants';
import { environment } from 'environments/environment';
import * as _ from 'lodash';
import { StorageService } from 'ngx-webstorage-service';
import { combineLatest, Observable, of, ReplaySubject, Subject, throwError } from 'rxjs';
import { catchError, map, shareReplay, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { CORE_SESSION_STORAGE, TPStorageService } from '../storage/tp-storage.service.ts';
import { ApirequestService, HttpVerb } from './api-service.service';
import { StatusTypes } from "../../utills";
import { SearchParameter } from 'app/models/general/system-models/search-models/search-parameter';
import { GlobalConfiguration } from "../../models/general/global-configuration";
import { RefDataService } from "./refData.service";
import { StorageKeys } from "../../utills/enums/storage-keys";
import { Router } from "@angular/router";

export interface UserServiceEvent {
    event: string;
}

@Injectable({
    providedIn: 'root',
})
export class UserService {

    static readonly CURRENT_USER_FETCHED_EVENT = 'currentUserFetched';

    private readonly _uri: string = '';
    private subject = new ReplaySubject<UserServiceEvent>(2);
    private endpoint = environment.apiUrl + '/user';

    private refreshCurrentUserObservers$ = new Subject();
    private refreshCurrentUserLock = false;

    constructor(
        public http: HttpClient,
        configService: AppConfigService,
        private authService: TpAuthService,
        @Inject(CORE_SESSION_STORAGE) private sessionStorage: StorageService,
        private api: ApirequestService,
        private globalConfiguration: GlobalConfiguration,
        private refDataService: RefDataService,
        private storageManager: TPStorageService,
        private router: Router,
    ) {
        if (configService) {
            this._uri = configService.getAPIUrl();
        }
    }

    /**
     * @function loadUser
     * @description load user data, configs. is called after user get authorized
     * @return void
     * */
    public loadUser(): void {
        this.refDataService.getCurrentUserCompanyBranchSetting().pipe(
            tap(res => {
                if (res.status) this.globalConfiguration.companyBranchSetting = res.response;
            })
        ).subscribe(res => {
            if (res.status) {
                this.authService.userLoad().subscribe(res => {
                    if (res) {
                        let storedPath = this.storageManager.read(StorageKeys.Redirect) as string;
                        this.router.navigateByUrl((storedPath && storedPath != '/login' && storedPath != '/signup') ? storedPath : '/' + res.portalUrl).then();
                    } else {
                        this.router.navigateByUrl('/login').then();
                    }
                }, (error: HttpErrorResponse) => {
                    this.router.navigateByUrl('/login').then();
                }
                );
            }
        })
    }

    checkUserPermissionsObservable(permissions: string[], allPermissions: boolean): Observable<boolean> {
        return combineLatest([
            this.currentUser(),
            this.getRoles(),
        ]).pipe(map(([user, roles]) => this.checkUserPermissions(permissions, allPermissions, user, roles)));
    }

    checkUserPermissions(permissions: string[], allPermissions: boolean, currentUser: UserModel, allRoles: RoleModel[]): boolean {
        if (!permissions || permissions.length === 0) {
            return true;
        }

        const currentUserRoles = allRoles.filter(r => currentUser.roleIds?.includes(r.roleId));
        const currentUserPrivileges = _.flatMap(currentUserRoles, r => r.rolePrivileges.map(rp => rp.privilegeCode));

        if (allPermissions) {
            return permissions.every(p => currentUserPrivileges.includes(p));
        } else {
            return permissions.some(p => currentUserPrivileges.includes(p));
        }
    }

    getProjectManagers(statusId: number): Observable<BaseResultModel<ResponseProjectManagerData[]>> {
        return this.api.ApiRequest<BaseResultModel<ResponseProjectManagerData[]>>(environment.apiUrl + '/projectmanager/' + statusId, '', new HttpHeaders(), HttpVerb.get, 'json');
    }
    getProjectManagersList(statusId: number): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + '/projectmanager/' + statusId + '/dropdown', '', new HttpHeaders(), HttpVerb.get, 'json');
    }


    getUsersDropdown(statusId: StatusTypes = StatusTypes.active): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(this.endpoint + '/' + statusId + '/dropdown', '', new HttpHeaders(), HttpVerb.get, 'json');
    }
    getallUsersDropDown(statusId: StatusTypes = StatusTypes.active): Observable<BaseResultModel<DropDownItem[]>> {
        const cachedUsers = localStorage.getItem('userOptions');

        if (cachedUsers) {
            const cachedData: BaseResultModel<DropDownItem[]> = {
                response: JSON.parse(cachedUsers),
                status: true
            };

            return of(cachedData);
        }


        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(
            this.endpoint + '/allUsers',
            '',
            new HttpHeaders(),
            HttpVerb.get,
            'json'
        ).pipe(
            tap(result => {
                if (result.response && result.status) {
                    localStorage.setItem('userOptions', JSON.stringify(result.response));
                }
            })
        );
    }


    isHardRefresh(): boolean {
        return performance?.navigation?.type === 1;
    }

    refreshProjectManagers(): Observable<SalesPersonsModel[]> {
        return this.api.ApiRequest<SalesPersonsModel[]>(`${this._uri}/ProjectManager/search`,
            '', new HttpHeaders(), HttpVerb.post, 'json')
            .pipe(
                tap((result: SalesPersonsModel[]) => {

                }),
            );
    }

    getSalesPersons(): Observable<SalesPersonsModel[]> {
        const persistedData = this.sessionStorage.get(
            Constants.SALES_PERSONS_KEY,
        ) as string;
        if (persistedData) {
            return of(<SalesPersonsModel[]>JSON.parse(persistedData));
        } else {
            return this.http
                .get<SalesPersonsModel[]>(`${this._uri}/api/User/salesPersons`)
                .pipe(
                    switchMap((result) => {
                        this.sessionStorage.set(
                            Constants.SALES_PERSONS_KEY,
                            JSON.stringify(result),
                        );
                        return of(result);
                    }),
                );
        }
    }

    getRoles(statusId: StatusTypes = StatusTypes.active): Observable<RoleModel[]> {
        return this.api.ApiRequest<BaseResultModel<RoleModel[]>>(environment.apiUrl + '/system/userRole/status/' + statusId, '', new HttpHeaders(), HttpVerb.get, 'json').pipe(
            map((data: BaseResultModel<RoleModel[]>) => {
                return data.response;
            })
        );
    }

    getRolesDropDown(statusId: StatusTypes = StatusTypes.active): Observable<DropDownItem[]> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + '/system/userRole/' + statusId + '/dropdown', '', new HttpHeaders(), HttpVerb.get, 'json').pipe(
            map((data: BaseResultModel<DropDownItem[]>) => {
                return data.response;
            })
        );
    }

    getUserInfo(userId: number): Observable<BaseResultModel<ResponseUserData>> {

        return this.api.ApiRequest<BaseResultModel<ResponseUserData>>(environment.apiUrl + '/user/' + userId?.toString(), '', new HttpHeaders(), HttpVerb.get, 'json');
    }

    refreshRoles(): Observable<RoleModel[]> {
        // TODO: Change this to use a refData -> refRole endpoint
        return this.http
            .post<SearchResult<RoleModel>>(`${this._uri}/api/Role/search`, { showAll: true })
            .pipe(
                map((result: SearchResult<RoleModel>) => {
                    this.sessionStorage.set(
                        Constants.ROLE_STORAGE_KEY,
                        JSON.stringify(result.result),
                    );
                    return result.result;
                }),
            );
    }

    currentUser(): Observable<UserModel> {
        return this.refreshCurrentUserObservers$.pipe(startWith(undefined))
            .pipe(map(() => {
                const persistedData: string = this.sessionStorage.get(Constants.CURRENT_USER_STORAGE_KEY) as string;
                if (persistedData) {
                    this.subject.next({
                        event: UserService.CURRENT_USER_FETCHED_EVENT,
                    });
                    return JSON.parse(persistedData) as UserModel;
                } else {
                    this.refreshCurrentUser().pipe(take(1)).subscribe();
                    return undefined as UserModel;
                }
            }));
    }

    refreshCurrentUser(): Observable<void> {
        if (this.refreshCurrentUserLock) {
            return of();
        } else {
            this.refreshCurrentUserLock = true;
            return this.http.get<UserModel>(`${this._uri}/user/current`)
                .pipe(map((result) => {
                    this.refreshCurrentUserLock = false;
                    this.sessionStorage.set(Constants.CURRENT_USER_STORAGE_KEY, JSON.stringify(result));
                    this.refreshCurrentUserObservers$.next();
                    return;
                }),
                    catchError(err => {
                        this.refreshCurrentUserLock = false;
                        return throwError(err);
                    }));
        }
    }

    currentUserTeamMembership(): Observable<UserTeamMembership> {
        const persistedData = this.sessionStorage.get(
            Constants.CURRENT_USER_TEAM_MEMBERSHIP_STORAGE_KEY,
        ) as string;
        if (persistedData) {
            return of(<UserTeamMembership>JSON.parse(persistedData));
        } else {
            return this.http
                .get<UserTeamMembership>(`${this._uri}/api/user/myDetails`)
                .pipe(shareReplay(1))
                .pipe(
                    switchMap((result) => {
                        this.sessionStorage.set(
                            Constants.CURRENT_USER_TEAM_MEMBERSHIP_STORAGE_KEY,
                            JSON.stringify(result),
                        );
                        return of(result);
                    }),
                );
        }
    }

    updateUserBlockViewSetting(model: UserPreferencesModel): Observable<UserModel> {
        const endpoint = `${this._uri}/api/user/blockViewSetting`;
        return this.http.put<UserModel>(`${endpoint}`, model).pipe(
            switchMap((result) => {
                this.sessionStorage.set(
                    Constants.CURRENT_USER_STORAGE_KEY,
                    JSON.stringify(result),
                );
                this.subject.next({ event: UserService.CURRENT_USER_FETCHED_EVENT });
                return of(result);
            }),
            tap(() => this.refreshCurrentUser().subscribe()),
        );
    }

    events(): Observable<UserServiceEvent> {
        return this.subject.asObservable();
    }

    pmDashboardDetails(): Observable<PMDashboardModel> {
        return of({} as any);
        // return this.http.get<PMDashboardModel>(`${this._uri}/user/pmDashboardTotals`);
    }

    getRefUser(): Observable<DropDownOptionsModel[]> {
        const persistedData: string = this.sessionStorage.get(
            Constants.USER_STORAGE_KEY,
        ) as string;
        if (persistedData) {
            return of(<DropDownOptionsModel[]>JSON.parse(persistedData));
        } else {
            return of(<DropDownOptionsModel[]>[]);
        }
    }

    refreshRefUser(): Observable<void> {
        return this.http
            .post<SearchResult<UserModel>>(
                `${this._uri}/api/user/search`,
                { showAll: true },
            )
            .pipe(
                switchMap((result: SearchResult<UserModel>): Observable<void> => {
                    this.sessionStorage.set(
                        Constants.USER_STORAGE_KEY,
                        JSON.stringify(result?.result?.map(user => ({
                            value: user.userId,
                            text: user.fname + ' ' + user.lname,
                        } as DropDownOptionsModel))),
                    );
                    return of();
                }),
            );
    }

    search(
        model: SearchParameter<UserSearch>,
    ): Observable<BaseResultModel<SearchResult<ResponseUserGridData>>> {
        return this.api.ApiRequest<BaseResultModel<SearchResult<ResponseUserGridData>>>(this.endpoint + '/search', model, new HttpHeaders(), HttpVerb.post, 'json');
    }

    addUser(formData): Observable<BaseResultModel<ResponseUserData>> {
        return this.http.post<BaseResultModel<ResponseUserData>>(this.endpoint + '/add', formData);
        //return this.api.ApiRequest<BaseResultModel<ResponseUserData>>(this.endpoint + '/add', formData, new HttpHeaders(), HttpVerb.post, 'json');
    }

    updateUser(model: PostUser): Observable<UserModel> {
        return this.api.ApiRequest<UserModel>(this.endpoint + '/update', model, new HttpHeaders(), HttpVerb.put, 'json');
    }

    sendResetPassword(emails: string[]): Observable<BaseResultModel<boolean>> {
        return this.api.ApiRequest<BaseResultModel<boolean>>(this.endpoint + '/reset-password/new-users', emails, new HttpHeaders(), HttpVerb.post, 'json');
    }

    updateUsername(userId: number, username: string): Observable<UserModel> {
        return this.api.ApiRequest<UserModel>(this.endpoint + '/id/' + userId + '/username', username, new HttpHeaders(), HttpVerb.put, 'json');
    }

    getUserById(id: number): Observable<BaseResultModel<ResponseUserData>> {
        return this.api.ApiRequest<BaseResultModel<ResponseUserData>>(this.endpoint + `/${id}`, id, new HttpHeaders(), HttpVerb.get, 'json');
    }

    getUserByIdGridData(id: number): Observable<BaseResultModel<ResponseUserGridData>> {
        return this.api.ApiRequest<BaseResultModel<ResponseUserGridData>>(this.endpoint + '/' + id + '/grid', '', new HttpHeaders(), HttpVerb.get, 'json');
    }

    deleteUserById(userId: number): Observable<BaseResultModel<boolean>> {
        return this.api.ApiRequest<BaseResultModel<boolean>>(this.endpoint + '/delete/' + userId, '', new HttpHeaders(), HttpVerb.delete, 'json');
    }

    changeUserPassword(model: UpdatePasswordModel): Observable<BaseResultModel<boolean>> {
        return this.api.ApiRequest<BaseResultModel<boolean>>(this.endpoint + '/change-password', model, new HttpHeaders(), HttpVerb.post, 'json');
    }

    /**
     * changes username of a registered user
     * @param userId 
     * @param userName 
     * @returns BaseResultModel<boolean>
     */
    changeUserName(userId: number, userName: string): Observable<BaseResultModel<boolean>> {
        return this.api.ApiRequest<BaseResultModel<boolean>>(this.endpoint + '/id/' + userId + '/username', userName, new HttpHeaders(), HttpVerb.put, 'json');
    }

    setUserStatusAndAvatar(model: PostSetUserStatusAndAvatar): Observable<BaseResultModel<boolean>> {
        return this.api.ApiRequest<BaseResultModel<boolean>>(this.endpoint + '/set-avatar-status', model, new HttpHeaders(), HttpVerb.post, 'json');
    }

    sendResetPasswordLink(userEmail: string): Observable<BaseResultModel<boolean>> {
        return this.api.ApiRequest<BaseResultModel<boolean>>(this.endpoint + '/reset-password', userEmail, new HttpHeaders(), HttpVerb.post, 'json');
    }

    validateResetPasswordToken(token: string): Observable<BaseResultModel<number>> {
        return this.api.ApiRequest<BaseResultModel<number>>(this.endpoint + '/validate-reset-pass-token', token, new HttpHeaders(), HttpVerb.post, 'json');
    }

    changeAnonymousUserPassword(model: UpdatePasswordModel): Observable<BaseResultModel<boolean>> {
        return this.api.ApiRequest<BaseResultModel<boolean>>(this.endpoint + '/change-password-anonymous', model, new HttpHeaders(), HttpVerb.post, 'json');
    }

    /***
     * @description checkes whether a username is valid or not
     * @returns BaseResultModel<boolean>
     */
    checkUsername(userName: string): Observable<BaseResultModel<boolean>> {
        return this.api.ApiRequest<BaseResultModel<boolean>>(this.endpoint + '/' + userName + '/checkUsernameValidity', '', new HttpHeaders(), HttpVerb.get, 'json');
    }
}
