export class ResponseToken
{
    public token:string;
    public expiration:string;
    public issueDate:string;
    public googleMFAEnabled: boolean;
    public googleMFABarcodeUrl: string;
    public googleMFASecretKey: string;
    public emailMFAEnabled: boolean;
    public mfaUserRegistered: string;
}

export class AuthenticateRequest
{
    public Username:string;
    public Password:string;
    public DomainUrl?:string;
    public MFAToken?:string;
}

export class ExternalAuthenticateRequest {
    authProvider?: string;
    token?: string;
    email?: string;
    domainUrl?: string;
}