import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FileExtensionTypes, FileTypes} from "../../utills/enums/file-types";
import {UtilsService} from "../../services/utils";
import {GlobalConfiguration} from "../../models/general/global-configuration";
import {PostFile, FileStatusTypes} from "../../models/general/system-models/post-file";
import {DropDownItem} from "../../models/general/dropdownItem.model";
import {RefDataService} from "../../services/general/refData.service";
import {Subject} from "rxjs";
import {getFileExtension, removeNullProperties} from "../../utills/functions";
import {HttpClient, HttpContext, HttpEvent, HttpEventType, HttpHeaders} from "@angular/common/http";
import {BaseResultModel} from "../../models";
import {AppConfigService} from "../../services/app.settings.service";
import {NotificationService} from "../../services/notification.service";
import {ResponseFile} from "../../models/general/system-models/response-file";
import {TranslateService} from "@ngx-translate/core";
import {ResponseIssueUploadUrlFile} from "../../models/general/system-models/response-issue-upload-url-file";
import {
    HttpContextSkippingHeaders
} from "../../services/auth/custom-http-interceptor.service";
import { Store } from "@ngrx/store";
import { mimeTypeDropdownEntity } from 'app/store/reducers';
import { State } from '../../store';
import { getMimeTypesDropdown } from 'app/store/actions/dropdowns.actions';

export class FileAutoUpload<T = object>{
    status: boolean;
    endpoint: string;
    issueUrlEndpoint: string;
    entity: T;
}

@Component({
  selector: 'tp-drag-and-drop-file',
  templateUrl: './drag-and-drop-file.component.html',
  styleUrls: ['./drag-and-drop-file.component.scss']
})
export class DragAndDropFileComponent implements OnInit, OnDestroy {
    @Output() selectedFiles: EventEmitter<PostFile[]> = new EventEmitter<PostFile[]>();
    @Output() filesUploaded: EventEmitter<PostFile[]> = new EventEmitter<PostFile[]>();
    @Output() fileUploaded: EventEmitter<PostFile> = new EventEmitter<PostFile>();
    @Output() filesSaved: EventEmitter<PostFile[]> = new EventEmitter<PostFile[]>();
    @Output() fileSaved: EventEmitter<PostFile> = new EventEmitter<PostFile>();
    @Input() multiple: boolean = true;
    @Input() minHeight: string = '300px';
    @Input() label: string = null;
    @Input() allowSelectButton: boolean = false;
    @Input() selectButtonLabel: string = null;
    @Input() fileType: FileTypes = FileTypes.General;
    @Input() files: PostFile[] = [];
    @Input() systemBorderRadius: boolean = false;
    @Input() set autoUpload(autoUploadConfig: Partial<FileAutoUpload>){
        if(autoUploadConfig) Object.assign(this._autoUpload, autoUploadConfig);
    }
    get autoUpload(): FileAutoUpload{
        return this._autoUpload;
    }
    private _autoUpload: FileAutoUpload = {status: true, endpoint: null, issueUrlEndpoint: '/file/issue-upload-url/s3', entity: null}
    @Output() filesChange: EventEmitter<PostFile[]> = new EventEmitter<PostFile[]>();
    @Output() selectButtonClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
    private mimeTypes: DropDownItem[] = [];
    private onDestroy$: Subject<void> = new Subject<void>();
    private uri: string;
    private fileExtensions: string[] = Object.values(FileExtensionTypes);
    public acceptExtensions: string = this.fileExtensions.map(extension => '.' + extension).join(',');
    constructor(
        private utils: UtilsService,
        private globalConfiguration: GlobalConfiguration,
        private refDataService: RefDataService,
        private http: HttpClient,
        private configService: AppConfigService,
        private notification: NotificationService,
        private translateService: TranslateService,
        private cdr: ChangeDetectorRef,
        private store: Store<State>
    ) {
        this.uri = this.configService.getAPIUrl();
        this.store.dispatch(getMimeTypesDropdown());
        this.store.select(mimeTypeDropdownEntity.selectors.selectAll)
        .subscribe(res=>{
            this.mimeTypes=res
        });
    }

    ngOnInit(): void {}
    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
    /**
     * @function onSelectedFiles
     * @description on dropped files or selected from browse
     * */
    onSelectedFiles(fileList: FileList): void{
        const selectedFiles = Array.from(fileList);
        if (selectedFiles?.length > 0) {
            let unSupportedExtensions: string[] = [];
            selectedFiles.forEach(file => {
                const fExtension: string = this.utils.getFileExtension(file);
                if(this.fileExtensions.includes(fExtension)) {
                    const mimeTypeId: number = this.mimeTypes.filter(x => x.name?.toLowerCase() == fExtension.toLowerCase())[0]?.id;
                    const postFile: PostFile = {
                        fileIndex: null,  //based on the index this files gets once inserted to the array
                        fileTypeId: this.fileType,
                        mimeTypeId: (mimeTypeId) ? mimeTypeId : 1,
                        fileId: null,
                        description: file?.name,
                        size: Math.round((file.size / 1024)),
                        progress: this.autoUpload.status,
                        status: FileStatusTypes.Pending,
                        loaded: 0,
                        file: file,
                        uid: Math.random().toString(16).slice(2),
                    };
                    this.files = [...this.files, postFile];
                } else if(!unSupportedExtensions.includes(fExtension)) unSupportedExtensions.push(fExtension);
            });
            this.selectedFiles.emit(this.getPendingFiles());
            if(this.autoUpload.status && !this.hasUploadingFiles() && this.hasPendingFiles()) this.uploadFile(this.getPendingFiles()[0]);
            this.filesChange.emit(this.files);
            if(unSupportedExtensions.length > 0){
                const unSupported = this.translateService.instant('tp-unsupported', {defaultText:'Unsupported files'});
                this.notification.showInfo(unSupportedExtensions.join(', '), unSupported)
            }
        }
    }
    /**
     * @function uploadFiles
     * @param file PostFile
     * @description upload file
     * @return void
     * */
    private uploadFile(file: PostFile): void {
        if(this.autoUpload.issueUrlEndpoint) { // get url to upload directly to AWS S3
            file.status = FileStatusTypes.Uploading;
            var fileMimeType=this.getFileMimeType(file.file);
            this.http.post<BaseResultModel<ResponseIssueUploadUrlFile>>(this.uri + this.autoUpload.issueUrlEndpoint, JSON.stringify(fileMimeType)).subscribe(result => {
                if(result.status) {
                    file.fileId = result.response.fileId;
                    this.uploadFileToS3(file, result.response.url);
                }
            }, error => {
                this.handleUploadError(file);
            });
        } else if(this.autoUpload.endpoint && this.autoUpload.entity) this.uploadFileToTP(file, this.uri + this.autoUpload.endpoint);
    }
    /**
     * @function uploadFile
     * @param file PostFile
     * @param url string
     * @description upload file recursively
     * @return void
     * */
    private uploadFileToS3(file: PostFile, url: string): void{
        this.http.put<void>(url, file.file, {
            reportProgress: true,
            observe: 'events',
            context: new HttpContext().set(HttpContextSkippingHeaders, true)
        }).subscribe(result => {
            this.handleUploadEvents(result, file);
        }, error => {
            this.handleUploadError(file);
        });
    }
    /**
     * @function uploadFile
     * @param file PostFile
     * @param url string
     * @description upload file recursively
     * @return void
     * */
    private uploadFileToTP(file: PostFile, url: string): void{
        let filePayload = {...file, ...this.autoUpload.entity};
        this.http.post<BaseResultModel<ResponseFile>>(url, this.prepareFormData(removeNullProperties(filePayload)), {
            reportProgress: true,
            observe: 'events'
        }).subscribe(result => {
            this.handleUploadEvents(result, file);
        }, error => {
            this.handleUploadError(file);
        });
    }
    /**
     * @function saveFileToTP
     * @param file PostFile
     * @param url string
     * @description upload file recursively
     * @return void
     * */
    private saveFileToTP(file: PostFile, url: string): void{
        let filePayload = {...file, ...this.autoUpload.entity, file: undefined};
        this.http.post<BaseResultModel<ResponseFile>>(url, removeNullProperties(filePayload)).subscribe(result => {
            const responseFileData = result.response;
            if(responseFileData) {
                Object.keys(responseFileData).forEach(key => {
                    file[key] = responseFileData[key];
                });
            }
            if(!this.hasPendingFiles() && !this.hasUploadingFiles()) this.filesSaved.emit(this.getUploadedFiles());
            this.fileSaved.emit(file);
        });
    }
    private handleUploadEvents(event: HttpEvent<any>, file: PostFile): void {
        switch (event.type) {
            case HttpEventType.Sent:
                file.status = FileStatusTypes.Uploading;
                break;
            case HttpEventType.UploadProgress:
                file.loaded = Math.round(100 * event.loaded / event.total);
                break;
            case HttpEventType.Response:
                const body = event.body;
                file.status = FileStatusTypes.Uploaded;
                if(body) {
                    const responseFileData = body.response;
                    if(responseFileData) {
                        Object.keys(responseFileData).forEach(key => {
                            file[key] = responseFileData[key];
                        });
                    }
                }
                if(this.autoUpload.issueUrlEndpoint && this.autoUpload.endpoint && this.autoUpload.entity) {
                    this.saveFileToTP(file,this.uri + this.autoUpload.endpoint);
                }
                this.fileUploaded.emit(file);
                this.notification.showSuccess('','File uploaded successfully');
                if (this.hasPendingFiles()) this.uploadFile(this.getPendingFiles()[0]); // recursive pending files to start uploading
                else this.filesUploaded.emit(this.getUploadedFiles());
                break;
        }
        this.cdr.markForCheck();
    }
    private handleUploadError(file: PostFile): void {
        file.status = FileStatusTypes.Rejected;
        this.notification.showError('','An error occurred');
        if (this.hasPendingFiles()) this.uploadFile(this.getPendingFiles()[0]); // recursive pending files to start uploading
        this.cdr.markForCheck();
    }
    /**
     * @function hasUploadingFiles
     * @description check if is any file uploading
     * @return boolean
     * */
    private hasUploadingFiles(): boolean {
        return !!this.files.find(file => file.status === FileStatusTypes.Uploading);
    }
    /**
     * @function hasPendingFiles
     * @description check if has pending files into list to upload
     * @return boolean
     * */
    private hasPendingFiles(): boolean {
        return !!this.files.find(file => file.status === FileStatusTypes.Pending);
    }
    /**
     * @function getPendingFiles
     * @description get pending files
     * @return PostFile[]
     * */
    private getPendingFiles(): PostFile[] {
        return this.files.filter(file => file.status === FileStatusTypes.Pending);
    }
    /**
     * @function getUploadedFiles
     * @description get uploaded files
     * @return PostFile[]
     * */
    private getUploadedFiles(): PostFile[] {
        return this.files.filter(file => file.status === FileStatusTypes.Uploaded);
    }
    /**
     * @function prepareFormData
     * @param file: PostClientFile
     * @description prepare form data to upload file for client
     * @return FormData
     * */
    private prepareFormData(file: PostFile): FormData{
        let formData: FormData = new FormData();
        
        for (const prop in file) {
            if (!file.hasOwnProperty(prop)) { }
            else formData.append(prop, file[prop]);
        }
        return formData;
    }
    private getFileMimeType(f:File):string{
        var res:string='application/octet-stream';
        
        if(f.type){
            res=f.type;
        }
        else{
            var mtMatches=this.mimeTypes?.filter(x=>x.name?.toLowerCase()==getFileExtension(f));

            if(mtMatches?.length>0){
                res=mtMatches[0].prefix.toString();
            }
        }
        return res;
    }
}