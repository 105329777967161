export class ResponseCalculateTotals {
    netAmount: number;
    grossAmount: number;
    totalAmount: number;
    taxAmount: number;
    discountAmount: number;
    surchargeAmount: number;
    extraChargeAmount: number;
    creditAmount: number;
    totalAfterDiscount: number;
    withHeldTaxAmount:number;
    constructor() {
        this.netAmount = 0;
        this.grossAmount = 0;
        this.totalAmount = 0;
        this.taxAmount = 0;
        this.discountAmount = 0;
        this.surchargeAmount = 0;
        this.extraChargeAmount = 0;
        this.creditAmount = 0;
        this.totalAfterDiscount = 0;
        this.withHeldTaxAmount = 0;
    }
}