import { createAction, props } from '@ngrx/store';

export enum DropdownsActionTypes {
    getProjectManagerDropdown = '[Project Managers / Api] Get Project Managers Dropdown',
    getLanguageDropdown = '[Languages / Api] Get Language Dropdown',
    getCurrencyDropdown = '[Currencies / Api] Get Currencies Dropdown',
    getCountryDropdown = '[Countries / Api] Get Countries Dropdown',
    getQuoteStatusDropdown = '[Quote Statuses / Api] Get Quote Statuses Dropdown',
    getProjectStatusDropdown = '[Project Statuses / Api] Get Project Statuses Dropdown',
    getJobStatusDropdown = '[Job Statuses / Api] Get Job Statuses Dropdown',
    getQuoteJobStatusDropdown = '[Quote Job Statuses / Api] Get Quote Job Statuses Dropdown',
    getInvoiceStatusDropdown = '[Invoice Statuses / Api] Get Invoice Statuses Dropdown',
    getTagDropdown = '[Tags / Api] Get Tags Dropdown',
    getTemplateDropdown = '[Templates / Api] Get Templates Dropdown',
    getResourceTypeDropdown = '[Resource Types / Api] Get Resource Types Dropdown',
    getResourceTeamDropdown = '[Resource Teams / Api] Get Resource Teams Dropdown',
    getGenderDropdown = '[Genders / Api] Get Genders Dropdown',
    getRoleDropdown = '[Roles / Api] Get Roles Dropdown',
    getClientDropdown = '[Client / Api] Get Client Dropdown',
    getLeadDropdown = '[Lead / Api] Get Lead Dropdown',
    getClientStatusDropdown = '[Client / Api] Get Client Status Dropdown',
    getResourceDropdown = '[Resource / Api] Get Resource Dropdown',
    getServiceDropdown = '[Service / Api] Get Service Dropdown',
    getServiceTypeDropdown = '[Service Types / Api] Get Service Types Dropdown',
    getWorkflowDropdown = '[Workflows / Api] Get Workflows Dropdown',
    getUnitDropdown = '[Unit / Api] Get Unit Dropdown',
    getUserDropdown = '[User / Api] Get User Dropdown',
    getMimeTypesDropdown = '[MimeTypes / Api] Get MimeTypes Dropdown',
}
export const getProjectManagerDropdown = createAction(
    DropdownsActionTypes.getProjectManagerDropdown
);
export const getLanguageDropdown = createAction(
    DropdownsActionTypes.getLanguageDropdown
);
export const getCurrencyDropdown = createAction(
    DropdownsActionTypes.getCurrencyDropdown
);
export const getCountryDropdown = createAction(
    DropdownsActionTypes.getCountryDropdown
);
export const getQuoteStatusDropdown = createAction(
    DropdownsActionTypes.getQuoteStatusDropdown
);
export const getProjectStatusDropdown = createAction(
    DropdownsActionTypes.getProjectStatusDropdown
);
export const getQuoteJobStatusDropdown = createAction(
    DropdownsActionTypes.getQuoteJobStatusDropdown
);
export const getJobStatusDropdown = createAction(
    DropdownsActionTypes.getJobStatusDropdown
);
export const getInvoiceStatusDropdown = createAction(
    DropdownsActionTypes.getInvoiceStatusDropdown
);
export const getTagDropdown = createAction(
    DropdownsActionTypes.getTagDropdown
);
export const getTemplateDropdown = createAction(
    DropdownsActionTypes.getTemplateDropdown
);
export const getResourceTypeDropdown = createAction(
    DropdownsActionTypes.getResourceTypeDropdown
);
export const getResourceTeamDropdown = createAction(
    DropdownsActionTypes.getResourceTeamDropdown
);
export const getGenderDropdown = createAction(
    DropdownsActionTypes.getGenderDropdown
);
export const getRoleDropdown = createAction(
    DropdownsActionTypes.getRoleDropdown
);
export const getClientDropdown = createAction(
    DropdownsActionTypes.getClientDropdown
);
export const getLeadDropdown = createAction(
    DropdownsActionTypes.getLeadDropdown
);
export const getClientStatusDropdown = createAction(
    DropdownsActionTypes.getClientStatusDropdown
);
export const getResourceDropdown = createAction(
    DropdownsActionTypes.getResourceDropdown
);
export const getServiceDropdown = createAction(
    DropdownsActionTypes.getServiceDropdown
);
export const getServiceTypeDropdown = createAction(
    DropdownsActionTypes.getServiceTypeDropdown
);
export const getWorkflowDropdown = createAction(
    DropdownsActionTypes.getWorkflowDropdown
);
export const getUnitDropdown = createAction(
    DropdownsActionTypes.getUnitDropdown
);
export const getUserDropdown = createAction(
    DropdownsActionTypes.getUserDropdown
);
export const getMimeTypesDropdown = createAction(
    DropdownsActionTypes.getMimeTypesDropdown
);