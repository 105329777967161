export enum PageTypes {
    // Pm pages & shared pages
    Dashboard = 1,
    Quotes = 2,
    Projects = 3,
    Clients = 4,
    Dashboards = 5,
    Apps = 6,
    Products = 7,
    CapacityPlanner = 8,
    Templates = 9,
    Absences = 10,
    AbsenceEntitlements = 11,
    Reports = 12,
    QuoteReports = 13,
    ProjectReports = 14,
    RevenueReports = 15,
    ProfitReports = 16,
    Users = 17,
    Roles = 18,
    SystemSettings = 19,
    FinanceSettings = 20,
    Services = 21,
    Units = 22,
    StatusSettings = 23,
    Numbering = 24,
    Tags = 25,
    QuotePaperworkTemplates = 26,
    ClientInvoiceTemplates = 27,
    PurchaseOrderTemplates = 28,
    Checklists = 29,
    Feedback = 30,
    Teams = 31,
    Resources = 32,
    ResourceJobs = 33,
    ResourcePurchaseOrders = 34,
    ResourceInvoices = 35,
    ResourcePayments = 36,
    ClientJobs = 37,
    ClientInvoices = 38,
    ClientPayments = 39,
    // end Pm pages
    // resource portal pages
    ResourceStats = 40,
    ResourceCompanyGuidelines = 41,
    // end resource portal pages
    // client portal pages
    ClientRequests = 42,
    ClientQuotes = 43,
    ClientProjects = 44,
    ClientReports = 45,
    ClientRevenueReports = 46,
    ClientProfitReports = 47,
    ClientFileManager = 48,
    Admin = 49,
    RequestAbsences = 50,
    CatCountDiscounts = 51,
    CatCountSchemas = 52,
    ResourcePortalCapacityPlanner = 53,
    Project = 54,
    ProfitVsLossReports = 55,
    ServicesReports = 56,
    SalesReports = 57,
    ClientPaymentReports = 58,
    ResourcePaymentReports = 59,
    PublicHolidays = 60,
    AbsenceTypes = 61,
    Role = 62,
    Unauthorized = 63,
    NotFounded = 64,
    EmailTemplates = 65,
    EmailTemplateType = 66,
    ResourceInvoiceTemplates = 67,
    PlanTimeTypes = 68,
    UtilizationReports = 69,
    ProductivityReports = 70,
    ClientProfile = 71,
    ClientPortal = 72,
    ClientPortalWidgets = 73,
    ResourcePortalWidgets = 74,
    PmPortalWidgets = 75,
    Requests = 76,
    ClientCredits = 77,
    Languages = 78,
    Announcements = 79,
    EmailLogs = 80,
    QuoteTemplates = 81,
    Workflows = 82,
    Staff = 83,
    ClientContacts = 84,
    Migrations = 85,
    AllLeads=86
    // end client portal pages
}