import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {catchError, map, concatMap, withLatestFrom, filter, switchMap} from 'rxjs/operators';
import { Observable, EMPTY, of } from 'rxjs';
import {DropdownsActionTypes} from "../../main/pm/store/actions";
import {Store} from "@ngrx/store";
import {State} from "../index";
import {RefDataService} from "../../services/general/refData.service";
import {
    clientDropdownEntity, clientStatusDropdownEntity,
    countryDropdownEntity,
    currencyDropdownEntity,
    genderDropdownEntity, invoiceStatusDropdownEntity, jobStatusDropdownEntity,
    languageDropdownEntity,
    leadDropdownEntity,
    mimeTypeDropdownEntity,
    projectManagerDropdownEntity,
    projectStatusDropdownEntity, quoteJobStatusDropdownEntity,
    quoteStatusDropdownEntity, resourceDropdownEntity,
    resourceTeamDropdownEntity,
    resourceTypeDropdownEntity,
    roleDropdownEntity, serviceDropdownEntity, serviceTypeDropdownEntity,
    tagDropdownEntity, templateDropdownEntity, unitDropdownEntity, userDropdownEntity, workflowDropdownEntity
} from "../reducers";
import {UserService} from "../../services/general/user.service";
import {ManageCategoriesService} from "../../main/admin/services/category/manage-categories.service";
import {ClientService} from "../../main/pm/services/clients/client.service";
import {ResourceService} from "../../main/pm/services/resources/resource.service";
import {ManageServicesService} from "../../main/admin/services/manage-services.service";
import {mapServiceGridDataToDropdown} from "../utils/service-mapping";
import {TemplateDropdownItem} from "../models/dropdowns/template-dropdown-item";
import {TranslateService} from "@ngx-translate/core";
import {ResourceTypes} from "../../utills";
import {ResourceDropDownItem} from "../models/dropdowns/resource-dropdown-item";
import {WorkflowService} from "../../main/admin/workflows/services/workflow.service";
import { DropDownItem } from 'app/models/general/dropdownItem.model';

@Injectable()
export class DropdownsEffects {
    getProjectManagerDropdown$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DropdownsActionTypes.getProjectManagerDropdown),
            withLatestFrom(this.store.select(projectManagerDropdownEntity.selectors.selectState)),
            filter(([action, state]) => !(state?.firstLoad)),
            switchMap(() =>
                this.userService.getProjectManagersList(1).pipe(
                    map(response => {
                        this.store.dispatch(projectManagerDropdownEntity.actions.updateAdditional({updates:{firstLoad: true}}));
                        const items = response.response.map(item => { item.isActive = (item.prefix === 'active'); return item;})
                        return projectManagerDropdownEntity.actions.setAll({entities: items})
                    }),
                    catchError(error => of(projectManagerDropdownEntity.actions.updateAdditional({updates:{error}})))
                )
            )
        );
    });
    getLanguageDropdown$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DropdownsActionTypes.getLanguageDropdown),
            withLatestFrom(this.store.select(languageDropdownEntity.selectors.selectState)),
            filter(([action, state]) => !(state?.firstLoad)),
            switchMap(() =>
                this.refDataService.getLanguagesSupported().pipe(
                    map(response => {
                        this.store.dispatch(languageDropdownEntity.actions.updateAdditional({updates:{firstLoad: true}}))
                        const items = response.response.map(item => { item.isActive = item.useAsDefaultFilter; return item;})
                        return languageDropdownEntity.actions.setAll({entities: items})
                    }),
                    catchError(error => of(languageDropdownEntity.actions.updateAdditional({updates:{error}})))
                )
            )
        );
    });
    getQuoteStatusDropdown$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DropdownsActionTypes.getQuoteStatusDropdown),
            withLatestFrom(this.store.select(quoteStatusDropdownEntity.selectors.selectState)),
            filter(([action, state]) => !(state?.firstLoad)),
            switchMap(() =>
                this.refDataService.getQuoteStatuses().pipe(
                    map(response => {
                        this.store.dispatch(quoteStatusDropdownEntity.actions.updateAdditional({updates:{firstLoad: true}}))
                        return quoteStatusDropdownEntity.actions.setAll({entities: response.response})
                    }),
                    catchError(error => {
                        this.store.dispatch(quoteStatusDropdownEntity.actions.updateAdditional({updates:{error, firstLoad: true}}))
                        return of(quoteStatusDropdownEntity.actions.setAll({entities: []}));
                    })
                )
            )
        );
    });
    getProjectStatusDropdown$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DropdownsActionTypes.getProjectStatusDropdown),
            withLatestFrom(this.store.select(projectStatusDropdownEntity.selectors.selectState)),
            filter(([action, state]) => !(state?.firstLoad)),
            switchMap(() =>
                this.refDataService.getProjectStatuses().pipe(
                    map(response => {
                        this.store.dispatch(projectStatusDropdownEntity.actions.updateAdditional({updates:{firstLoad: true}}))
                        return projectStatusDropdownEntity.actions.setAll({entities: response.response})
                    }),
                    catchError(error => {
                        this.store.dispatch(projectStatusDropdownEntity.actions.updateAdditional({updates:{error, firstLoad: true}}))
                        return of(projectStatusDropdownEntity.actions.setAll({entities: []}))
                    })
                )
            )
        );
    });
    getJobStatusDropdown$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DropdownsActionTypes.getJobStatusDropdown),
            withLatestFrom(this.store.select(jobStatusDropdownEntity.selectors.selectState)),
            filter(([action, state]) => !(state?.firstLoad)),
            switchMap(() =>
                this.refDataService.getJobStatuses().pipe(
                    map(response => {
                        this.store.dispatch(jobStatusDropdownEntity.actions.updateAdditional({updates:{firstLoad: true}}))
                        return jobStatusDropdownEntity.actions.setAll({entities: response.response})
                    }),
                    catchError(error => {
                        this.store.dispatch(invoiceStatusDropdownEntity.actions.updateAdditional({updates:{error, firstLoad: true}}));
                        return of(invoiceStatusDropdownEntity.actions.setAll({entities: []}))
                    })
                )
            )
        );
    });
    getInvoiceStatusDropdown$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DropdownsActionTypes.getInvoiceStatusDropdown),
            withLatestFrom(this.store.select(invoiceStatusDropdownEntity.selectors.selectState)),
            filter(([action, state]) => !(state?.firstLoad)),
            switchMap(() =>
                this.refDataService.getInvoiceStatuses().pipe(
                    map(response => {
                        this.store.dispatch(invoiceStatusDropdownEntity.actions.updateAdditional({updates:{firstLoad: true}}))
                        return invoiceStatusDropdownEntity.actions.setAll({entities: response.response})
                    }),
                    catchError(error => {
                        this.store.dispatch(invoiceStatusDropdownEntity.actions.updateAdditional({updates:{error, firstLoad: true}}));
                        return of(invoiceStatusDropdownEntity.actions.setAll({entities: []}))
                    })
                )
            )
        );
    });
    getCurrencyDropdown$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DropdownsActionTypes.getCurrencyDropdown),
            withLatestFrom(this.store.select(currencyDropdownEntity.selectors.selectState)),
            filter(([action, state]) => !(state?.firstLoad)),
            switchMap(() =>
                this.refDataService.getCurrencies().pipe(
                    map(response => {
                        this.store.dispatch(currencyDropdownEntity.actions.updateAdditional({updates:{firstLoad: true}}))
                        return currencyDropdownEntity.actions.setAll({entities: response.response})
                    }),
                    catchError(error => of(currencyDropdownEntity.actions.updateAdditional({updates:{error}})))
                )
            )
        );
    });
    getCountryDropdown$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DropdownsActionTypes.getCountryDropdown),
            withLatestFrom(this.store.select(countryDropdownEntity.selectors.selectState)),
            filter(([action, state]) => !(state?.firstLoad)),
            switchMap(() =>
                this.refDataService.getCountries().pipe(
                    map(response => {
                        this.store.dispatch(countryDropdownEntity.actions.updateAdditional({updates:{firstLoad: true}}))
                        return countryDropdownEntity.actions.setAll({entities: response.response})
                    }),
                    catchError(error => of(countryDropdownEntity.actions.updateAdditional({updates:{error}})))
                )
            )
        );
    });
    getTagDropdown$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DropdownsActionTypes.getTagDropdown),
            withLatestFrom(this.store.select(tagDropdownEntity.selectors.selectState)),
            filter(([action, state]) => !(state?.firstLoad)),
            switchMap(() =>
                this.categoriesService.getCategoriesGrid().pipe(
                    map(response => {
                        this.store.dispatch(tagDropdownEntity.actions.updateAdditional({updates:{firstLoad: true}}))
                        return tagDropdownEntity.actions.setAll({
                            entities: response.response.map(category =>  {
                                return {
                                    id: category.id,
                                    name: category.description + (category.tagCategoryTypeDescription ? ' (' + category.tagCategoryTypeDescription + ')' : ''),
                                    tagCategoryTypeId: category.tagCategoryTypeId
                                }
                            })
                        })
                    }),
                    catchError(error => of(tagDropdownEntity.actions.updateAdditional({updates:{error}})))
                )
            )
        );
    });
    getTemplateDropdown$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DropdownsActionTypes.getTemplateDropdown),
            withLatestFrom(this.store.select(templateDropdownEntity.selectors.selectState)),
            filter(([action, state]) => !(state?.firstLoad)),
            switchMap(() =>
                this.refDataService.getHtmlTemplatesByStatus().pipe(
                    map(response => {
                        this.store.dispatch(templateDropdownEntity.actions.updateAdditional({updates:{firstLoad: true}}))
                        return templateDropdownEntity.actions.setAll({
                                entities: response.response.map(template =>  {
                                    return <TemplateDropdownItem>{id: template.id, name: template.name, isActive: !!template.prefix, isDefault: !!template.useAsDefaultFilter, category: template.icon};
                                }),
                        })
                    }),
                    catchError(error => of(templateDropdownEntity.actions.updateAdditional({updates:{error}})))
                )
            )
        );
    });
    getResourceTypeDropdown$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DropdownsActionTypes.getResourceTypeDropdown),
            withLatestFrom(this.store.select(resourceTypeDropdownEntity.selectors.selectState)),
            filter(([action, state]) => !(state?.firstLoad)),
            switchMap(() =>
                this.refDataService.getResourceTypes().pipe(
                    map(response => {
                        if(response.response?.length > 0){
                            response.response.map(resourceType => {
                                switch (resourceType.id){
                                    case ResourceTypes.Agency:
                                        resourceType.textAsync = this.translateService.get('tp-agency', {defaultText:'Agency'});
                                        break;
                                    case ResourceTypes.Employee:
                                        resourceType.textAsync = this.translateService.get('tp-staff', {defaultText:'Staff'});
                                        break;
                                    case ResourceTypes.Freelancer:
                                        resourceType.textAsync = this.translateService.get('tp-freelance-resource', {defaultText:'Freelance resource'});
                                        break;
                                    case ResourceTypes.InHouse:
                                        resourceType.textAsync = this.translateService.get('tp-inhouse-resource', {defaultText:'Inhouse resource'});
                                        break;
                                    case ResourceTypes.ProjectManager:
                                        resourceType.textAsync = this.translateService.get('tp-project-manager', {defaultText:'Project manager'});
                                        break;
                                    default:
                                        break;
                                }
                            });
                        }
                        this.store.dispatch(resourceTypeDropdownEntity.actions.updateAdditional({updates:{firstLoad: true}}))
                        return resourceTypeDropdownEntity.actions.setAll({entities: response.response})
                    }),
                    catchError(error => of(resourceTypeDropdownEntity.actions.updateAdditional({updates:{error}})))
                )
            )
        );
    });
    getResourceTeamDropdown$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DropdownsActionTypes.getResourceTeamDropdown),
            withLatestFrom(this.store.select(resourceTeamDropdownEntity.selectors.selectState)),
            filter(([action, state]) => !(state?.firstLoad)),
            switchMap(() =>
                this.refDataService.getResourceTeams().pipe(
                    map(response => {
                        this.store.dispatch(resourceTeamDropdownEntity.actions.updateAdditional({updates:{firstLoad: true}}))
                        return resourceTeamDropdownEntity.actions.setAll({entities: response.response})
                    }),
                    catchError(error => of(resourceTeamDropdownEntity.actions.updateAdditional({updates:{error}})))
                )
            )
        );
    });
    getGenderDropdown$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DropdownsActionTypes.getGenderDropdown),
            withLatestFrom(this.store.select(genderDropdownEntity.selectors.selectState)),
            filter(([action, state]) => !(state?.firstLoad)),
            switchMap(() =>
                this.refDataService.getGenders().pipe(
                    map(response => {
                        this.store.dispatch(genderDropdownEntity.actions.updateAdditional({updates:{firstLoad: true}}))
                        return genderDropdownEntity.actions.setAll({entities: response.response})
                    }),
                    catchError(error => of(genderDropdownEntity.actions.updateAdditional({updates:{error}})))
                )
            )
        );
    });
    getRoleDropdown$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DropdownsActionTypes.getRoleDropdown),
            withLatestFrom(this.store.select(roleDropdownEntity.selectors.selectState)),
            filter(([action, state]) => !(state?.firstLoad)),
            switchMap(() =>
                this.userService.getRolesDropDown().pipe(
                    map(response => {
                        this.store.dispatch(roleDropdownEntity.actions.updateAdditional({updates:{firstLoad: true}}))
                        return roleDropdownEntity.actions.setAll({entities: response})
                    }),
                    catchError(error => of(roleDropdownEntity.actions.updateAdditional({updates:{error}})))
                )
            )
        );
    });
    clientDropdown$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DropdownsActionTypes.getClientDropdown),
            withLatestFrom(this.store.select(clientDropdownEntity.selectors.selectAll)),
            filter(([action, clientDropdown]) => !(clientDropdown?.length > 0)),
            switchMap(() =>
                this.clientService.getClientsDropdown().pipe(
                    map(response => clientDropdownEntity.actions.addMany({entities: response.response.map(client => {
                        const openParenthesesIndex = client.name.indexOf('(');
                        const closeParenthesesIndex = client.name.indexOf(')');
                        const code = client.name.substr(openParenthesesIndex, closeParenthesesIndex);
                        if(openParenthesesIndex > -1 && closeParenthesesIndex > -1) client.name = client.name.replace(code, '');
                        client.isActive = (client.prefix === "active");
                        return client;
                    })})),
                    catchError(error => of(clientDropdownEntity.actions.updateAdditional({updates:{error}})))
                )
            )
        );
    });
    leadDropdown$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DropdownsActionTypes.getLeadDropdown),
            withLatestFrom(this.store.select(leadDropdownEntity.selectors.selectAll)),
            filter(([action, leadDropdown]) => !(leadDropdown?.length > 0)),
            switchMap(() =>
                this.clientService.getLeadsDropdown().pipe(
                    map(response => leadDropdownEntity.actions.addMany({entities: response.response.map(client => {
                        const openParenthesesIndex = client.name.indexOf('(');
                        const closeParenthesesIndex = client.name.indexOf(')');
                        const code = client.name.substr(openParenthesesIndex, closeParenthesesIndex);
                        if(openParenthesesIndex > -1 && closeParenthesesIndex > -1) client.name = client.name.replace(code, '');
                       // client.isActive = (client.prefix === "active");
                        return client;
                    })})),
                    catchError(error => of(leadDropdownEntity.actions.updateAdditional({updates:{error}})))
                )
            )
        );
    });
    getClientStatusDropdown$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DropdownsActionTypes.getClientStatusDropdown),
            withLatestFrom(this.store.select(clientStatusDropdownEntity.selectors.selectAll)),
            filter(([action, clientStatusDropdown]) => !(clientStatusDropdown?.length > 0)),
            switchMap(() =>
                this.clientService.getClientStatuses().pipe(
                    map(response => {
                        this.store.dispatch(clientStatusDropdownEntity.actions.updateAdditional({updates:{firstLoad: true}}));
                        return clientStatusDropdownEntity.actions.setAll({entities: response.response});
                    }),
                    catchError(error => {
                        this.store.dispatch(clientStatusDropdownEntity.actions.updateAdditional({updates:{error, firstLoad: true}}));
                        return of(clientStatusDropdownEntity.actions.setAll({entities: []}));
                    })
                )
            )
        );
    });
    getQuoteJobStatusDropdown$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DropdownsActionTypes.getQuoteJobStatusDropdown),
            withLatestFrom(this.store.select(quoteJobStatusDropdownEntity.selectors.selectAll)),
            filter(([action, entities]) => !(entities?.length > 0)),
            switchMap(() =>
                this.refDataService.getQuoteJobStatuses().pipe(
                    map(response => quoteJobStatusDropdownEntity.actions.setAll({entities: response.response})),
                    catchError(error => of(quoteJobStatusDropdownEntity.actions.updateAdditional({updates:{ error }})))
                )
            )
        );
    });
    resourceDropdown$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DropdownsActionTypes.getResourceDropdown),
            withLatestFrom(this.store.select(resourceDropdownEntity.selectors.selectAll)),
            filter(([action, resourceDropdown]) => !(resourceDropdown?.length > 0)),
            switchMap(() =>
                this.resourceService.getActiveResourcesWithCurrencyDropdown().pipe(
                    map(response => {
                        const items: ResourceDropDownItem[] = response.response.map(item => {
                            return {...item,isActive: item.useAsDefaultFilter, currencyId: Number(item.icon), resourceTypeId: Number(item.prefix)};
                        });
                        return resourceDropdownEntity.actions.addMany({entities: items})
                    }),
                    catchError(error => of(resourceDropdownEntity.actions.updateAdditional({updates:{error}})))
                )
            )
        );
    });
    getServiceDropdown$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DropdownsActionTypes.getServiceDropdown),
            withLatestFrom(this.store.select(serviceDropdownEntity.selectors.selectAll)),
            filter(([action, serviceDropdown]) => !(serviceDropdown?.length > 0)),
            switchMap(() =>
                this.manageService.getServicesGridData().pipe(
                    map(response => serviceDropdownEntity.actions.setAll({entities: response.response.map(service =>  {return mapServiceGridDataToDropdown(service)} )})),
                    catchError(error => of(serviceDropdownEntity.actions.updateAdditional({updates:{error}})))
                )
            )
        );
    });
    getServiceTypeDropdown$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DropdownsActionTypes.getServiceTypeDropdown),
            withLatestFrom(this.store.select(serviceTypeDropdownEntity.selectors.selectAll)),
            filter(([action, serviceTypeDropdown]) => !(serviceTypeDropdown?.length > 0)),
            switchMap(() =>
                this.refDataService.getServiceTypesDropdown().pipe(
                    map(response => serviceTypeDropdownEntity.actions.setAll({
                        entities: response.response
                    })),
                    catchError(error => of(serviceTypeDropdownEntity.actions.updateAdditional({updates:{error}})))
                )
            )
        );
    });
    getWorkflowDropdown$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DropdownsActionTypes.getWorkflowDropdown),
            withLatestFrom(this.store.select(workflowDropdownEntity.selectors.selectAll)),
            filter(([action, items]) => !(items?.length > 0)),
            switchMap(() =>
                this.workflowService.getWorkflowsDropdown().pipe(
                    map(response => workflowDropdownEntity.actions.setAll({
                        entities: response.response.map(item => {
                           return {...item, isActive: item.isActive ?? null}
                        })
                    })),
                    catchError(error => of(workflowDropdownEntity.actions.updateAdditional({updates:{error}})))
                )
            )
        );
    });
    getUnitDropdown$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DropdownsActionTypes.getUnitDropdown),
            withLatestFrom(this.store.select(unitDropdownEntity.selectors.selectAll)),
            filter(([action, unitDropdown]) => !(unitDropdown?.length > 0)),
            switchMap(() =>
                this.refDataService.getUnits().pipe(
                    map(response => unitDropdownEntity.actions.setAll({entities: response.response})),
                    catchError(error => of(unitDropdownEntity.actions.updateAdditional({updates:{error}})))
                )
            )
        );
    });
    getUserDropdown$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DropdownsActionTypes.getUserDropdown),
            withLatestFrom(this.store.select(userDropdownEntity.selectors.selectAll)),
            filter(([action, userDropdown]) => !(userDropdown?.length > 0)),
            switchMap(() =>
                this.userService.getUsersDropdown().pipe(
                    map(response => userDropdownEntity.actions.setAll({entities: response.response})),
                    catchError(error => of(userDropdownEntity.actions.updateAdditional({updates:{error}})))
                )
            )
        );
    });
    getMimeTypesDropdown$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DropdownsActionTypes.getMimeTypesDropdown),
            withLatestFrom(this.store.select(mimeTypeDropdownEntity.selectors.selectState)),
            filter(([action, state]) => !(state?.firstLoad)),
            switchMap(() =>
                this.refDataService.getMimeTypes().pipe(
                    map(response => {
                        this.store.dispatch(mimeTypeDropdownEntity.actions.updateAdditional({updates:{firstLoad: true}}))
                        return mimeTypeDropdownEntity.actions.setAll({entities: response.response})
                    }),
                    catchError(error => of(mimeTypeDropdownEntity.actions.updateAdditional({updates:{error}})))
                )
            )
        );
    });
  constructor(
      private actions$: Actions,
      private store: Store<State>,
      private refDataService: RefDataService,
      private userService: UserService,
      private categoriesService: ManageCategoriesService,
      private clientService: ClientService,
      private resourceService: ResourceService,
      private manageService: ManageServicesService,
      private translateService: TranslateService,
      private workflowService: WorkflowService
  ) {}

}