import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ResponseUserData} from 'app/main/admin/models/user.model';
import {BaseResultModel} from 'app/models';
import {GlobalConfiguration} from 'app/models/general/global-configuration';
import {AuthenticateRequest, ExternalAuthenticateRequest, ResponseToken} from 'app/models/general/token';
import {PostIdpUser, ResponseSamlUser, UserData} from 'app/models/general/user-data';
import {environment} from 'environments/environment';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {ApirequestService, HttpVerb} from '../general/api-service.service';
import {TPStorageService} from '../storage/tp-storage.service.ts';
import {UtilsService} from '../utils';
import {RoleTypes} from "../../utills";
import {tap} from "rxjs/operators";
import {PortalTypes} from 'app/utills/enums/portal-types';
import {RefDataService} from "../general/refData.service";
import {LayoutService} from "../../layout/layout.service";
import {PostRegister, PostVerifyRegistration} from "../../models/signup/signup";
import {StorageKeys} from "../../utills/enums/storage-keys";
import {Store} from "@ngrx/store";
import {State} from "../../store";
import {Logout} from "../../store/actions";

@Injectable()
export class TpAuthService {
    isResourcePortal$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isClientPortal$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isPmPortal$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isPmRole$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isAdminRole$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    constructor(
        private api:ApirequestService,
        private storageService: TPStorageService,
        private utils:UtilsService,
        private globalConfig:GlobalConfiguration,
        private http: HttpClient,
        private refDataService: RefDataService,
        private layoutService: LayoutService,
        private store: Store<State>
    ) {}
    /***
     * @description Clears the local session storage from entries
     */
    logout(): void {
        this.storageService.clearLocalStorage();
        this.globalConfig.currentUser = new UserData();
        this.store.dispatch(Logout());
    }
    /**
   * @description authentication function
   * @param username 
   * @param password
   * @returns BaseResultModel<ResponseToken>
   */
    login(username:string, password:string, token: string): Observable<BaseResultModel<ResponseToken>> {
        let authReq = new AuthenticateRequest;
        authReq.Username = username;
        authReq.Password = password;
        authReq.DomainUrl = environment.domainUrl;
        authReq.MFAToken = token;

        //in case user has set to stay signed in 
        return this.api.ApiRequest<BaseResultModel<ResponseToken>>(environment.apiUrl + '/user/login',authReq,new HttpHeaders(),HttpVerb.post,'json').pipe(
            tap(data => {
                if(data.status && data.response && data.response.token != null) this.storageService.writeLocalStorage(StorageKeys.Auth, JSON.stringify(data.response));
            })
        );
    }

    /**
   * @description send 2 factor email code function
   * @param username 
   * @param password
   * @returns BaseResultModel<ResponseToken>
   */
    sendEmail2FACode(username:string, password:string): Observable<BaseResultModel<ResponseToken>> {
        let authReq = new AuthenticateRequest;
        authReq.Username = username;
        authReq.Password = password;
        authReq.DomainUrl = environment.domainUrl;

        //in case user has set to stay signed in 
        return this.api.ApiRequest<BaseResultModel<ResponseToken>>(environment.apiUrl + '/user/send-email-2FA-code',authReq,new HttpHeaders(),HttpVerb.post,'json').pipe(
            tap(data => {
                if(data.status && data.response && data.response.token != null) this.storageService.writeLocalStorage(StorageKeys.Auth, JSON.stringify(data.response));
            })
        );
    }

    /**
     * @function register
     * @description register
     * @param payload  PostRegister
     * @returns BaseResultModel<boolean>
     */
    register(payload: PostRegister): Observable<BaseResultModel<boolean>> {
       return this.http.post<BaseResultModel<boolean>>(environment.apiUrl + '/user/register' ,payload);
    }
    /**
     * @function verifyRegistration
     * @description verify registration
     * @param payload  PostVerifyRegistration
     * @returns BaseResultModel<boolean>
     */
    verifyRegistration(payload: PostVerifyRegistration): Observable<BaseResultModel<ResponseToken>>{
        return this.http.post<BaseResultModel<ResponseToken>>(environment.apiUrl + '/user/register/validate' ,payload).pipe(
            tap(data => {
                if(data.status) this.storageService.writeLocalStorage(StorageKeys.Auth ,JSON.stringify(data.response));
            })
        );
    }
    /**
   * @description external provider authentication function
   * @param token  ExternalAuthenticateRequest
   * @returns toBaseResultModel<ResponseToken>ken
   */
    loginWithExtProvider(token:ExternalAuthenticateRequest): Observable<BaseResultModel<ResponseToken>>{
    return this.api.ApiRequest<BaseResultModel<ResponseToken>>(environment.extAuthUrl,token,new HttpHeaders(),HttpVerb.post,'json').pipe(
        tap(data => {
            if(data.status) this.storageService.writeLocalStorage(StorageKeys.Auth, JSON.stringify(data.response));
        })
    );
    }
    /**
     * @function getAccessToken
     * @description get token
     * @returns string
     */
    getAccessToken(): string {
        const token = this.storageService.readLocalStorage(StorageKeys.Auth);
        if (token && token != ''){
            const issuedIn: Date = new Date(JSON.parse(token).issueDate);
            if (issuedIn) {
                const currentDate: Date = new Date();
                const expirationSeconds: number = Number(JSON.parse(token).expiration);
                const expiresIn:Date = this.utils.addSecondsToDate(expirationSeconds,issuedIn);
                if (expiresIn >= currentDate) {
                    return JSON.parse(token).token;
                } else {
                    return '';
                }
            }
        } else {
            return '';
        }
    }
    /**
     * @function hasAccessToken
     * @description check if has token
     * @returns boolean
     */
    hasAccessToken(): boolean {
        return (this.getAccessToken() && this.getAccessToken() != '');
    }
    /**
     * @function userLoad
     * @description get user data
     * @returns Observable<UserData>
     */
    userLoad(): Observable<UserData> {
        return this.http.get<UserData>(environment.apiUrl + '/user/current').pipe(
            tap(user => {
                this.globalConfig.currentUser = user;
                this.getCompanySettings();
                this.isClientPortal$.next(this.isClientPortal());
                this.isResourcePortal$.next(this.isResourcePortal());
                this.isPmPortal$.next(this.isPmPortal());
                this.isAdminRole$.next(this.validateRole(RoleTypes.Administrator));
                this.isPmRole$.next(this.validateRole(RoleTypes.ProjectManager));
                this.layoutService.displayNavbar(true);
            })
        );
    }
    /**
     * @function getCompanySettings
     * @description get company settings
     * @returns  void
     */
    getCompanySettings(): void {
        this.refDataService.getCompanySetting(1).subscribe(res => {
            if(res.status) this.globalConfig.companySetting = res.response;
        });
    }
    /**
     * @function checkUserPrivilegesObservable
     * @description check user privileges
     * @returns  Observable<boolean>
     */
    checkUserPrivilegesObservable(permissions: string[], allPermissions: boolean): Observable<boolean> {
        return of();
    }
    /**
     * @function validateUser
     * @description validate user
     * @returns  boolean
     */
    validateUser(userId: number): boolean {
        return (userId === this.globalConfig?.currentUser?.id);
    }
    /**
     * @function validateRole
     * @description validate user role
     * @returns  boolean
     */
    validateRole(roleId: RoleTypes): boolean {
        return (roleId === this.globalConfig?.currentUser?.userRoleId);
    }
    /**
     * @function validateRoles
     * @description validate user roles
     * @returns  boolean
     */
    validateRoles(roleIds: RoleTypes[]): boolean {
        return (roleIds.includes(this.globalConfig?.currentUser?.userRoleId));
    }
    /**
     * @function validateRole$
     * @description validate user roles observable
     * @returns  Observable<boolean>
     */
    validateRole$(roleId: RoleTypes): Observable<boolean>{
        return  of(this.validateRole(roleId));
    }
    /**
     * @function redirectUser
     * @description redirect user based on it's role
     * @param user  ResponseUserData
     * @returns string
     */
    redirectUser(user: ResponseUserData = null): string {
            if (this.globalConfig?.currentUser?.portalId){ 
                switch (this.globalConfig?.currentUser?.portalId) {
                    case PortalTypes.Resource:
                        return '/resource/dashboard';
                    case PortalTypes.Client:
                        return '/client/dashboard';
                    default: return '/pm/dashboard';
                }
            } else { 
                return '/pm/dashboard';
            }
        }
    /**
     * @function isPmPortal
     * @description check if the user is a PM
     * @returns boolean
     */
    isPmPortal(): boolean {
        return this.validatePortal(PortalTypes.Main);
    }
    /**
     * @function isResourcePortal
     * @description check if the user is a resource
     * @returns boolean
     */
    isResourcePortal(): boolean {
        return this.validatePortal(PortalTypes.Resource)
    }
    /**
     * @function isClientPortal
     * @description check if the user is a client
     * @returns boolean
     */
    isClientPortal(): boolean {
        return this.validatePortal(PortalTypes.Client)
    }
        /**
         * @function validatePortal
         * @description validate portal
         * @returns boolean
         */
    validatePortal(portalType: number): boolean { 
        return (this.globalConfig?.currentUser.portalId == portalType);
    }
        /**
         * @function isLoggedInUser
         * @description check if is logged in user
         * @returns boolean
         */
    isLoggedInUser(id: number): boolean{
        return this.globalConfig?.currentUser?.id === id;
    }
    /**
     * @function getIdpUserResponseByToken
     * @description fetches relative responded saml user data after authentication
     * @returns Observable<BaseResultModel<ResponseSamlUser>>
     */
    getIdpUserResponseByToken(token: string): Observable<BaseResultModel<ResponseSamlUser>>{
        return this.api.ApiRequest<BaseResultModel<ResponseSamlUser>>(environment.authApiUrl + '/idp/response/token/' + token,'',new HttpHeaders(),HttpVerb.get,'json');
    }
    /**
     * @function checkIdpUser
     * @description checks if user exists and adds it if necessary
     * @returns Observable<BaseResultModel<ResponseToken>>
     */
    checkIdpUser(user: PostIdpUser): Observable<BaseResultModel<ResponseToken>>{
        return this.api.ApiRequest<BaseResultModel<ResponseToken>>(environment.apiUrl + '/user/idp/user/check',user,new HttpHeaders(),HttpVerb.post,'json');
    }
}