export enum PermissionTypes{
    //Tabs=======================================
    DisplayQuotesTab=1,
    DisplayProjectsTab=2,
    DisplayClientsTab=3,
    DisplayResourcesTab=4,
    DisplayOptionsTab=5,
    DisplayReportsTab=6,
    DisplayAdminTab=7,
    DisplayTemplatesTab=235,
    DisplayPlannerTab=228,
    RPDisplayPlannerTab=241,
    DisplayCRMTab=400,

    //Pages======================================
    AllQuotes=8,
    AllQuoteJobs=9,
    AllProjects=10,
    AllClients=11,
    AllClientJobs=12,
    AllClientInvoices=13,
    AllClientPayments=14,
    AllResources=15,
    AllResourceJobs=16,
    AllPurchaseOrders=17,
    AllResourceInvoices=18,
    AllResourcePayments=19,


    // Essential Permissions For PM Portal==========

    Essential = 243,
    CreateLeaveRequest = 244,
    EditLeaveRequest = 245,
    ViewLeaveRequest = 246,
    LeaveRequest = 247,

    //General Permissions for PM Portal==========
    Teams=20,
    Checklists=21,
    Feedback=22,
    Absences=27,
    AbsenceTypes = 27,
    PublicHolidays = 27,
    Users=28,
    Roles=29,
    CompanySettings=30,
    FinancialSettings=31,
    Services=32,
    Units=33,
    Statuses=34,
    Numbering=35,
    Tags=36,
    Integrations=37,
    QuotePaperworkTemplates=38,
    InvoiceTemplates=39,
    POTemplates=40,
    EmailTemplates=41,
    AbsenceEntitlement=42,
    CATCountDiscounts=43,
    CATToolSchemas=44,
    CreateNewQuote=45,
    ExportQuotes=46,
    ViewQuote=47,
    EditQuote=48,
    DeleteQuote=49,
    CopyQuote=50,
    LogManualChase=51,
    ChangeStatus=52,
    CreateProjectfromQuote=53,
    ViewQuoteJobs=54,
    CreateNewQuoteJob=55,
    DeleteQuoteJob=56,
    DuplicateQuoteJob=57,
    EditQuoteJob=58,
    CreateNewProject=59,
    CopyProject=60,
    ChangeProjectStatus=61,
    EditProject=62,
    ExportProjects=63,
    CreateNewClient=64,
    ChangeClientStatus=65,
    EditClient=66,
    ExportClients=67,
    ViewClient=68,
    CreateNewClientJob=69,
    EditClientJob=70,
    ExportClientJob=71,
    ViewClientJob=72,
    DeleteClientJob=73,
    CreateNewClientInvoice=74,
    DeleteClientInvoice=75,
    EditClientInvoice=76,
    ExportClientInvoices=77,
    ChaseClientInvoice=78,
    SendClientInvoice=79,
    ViewClientInvoice=80,
    CreateNewClientPayment=81,
    EditClientPayment=82,
    ExportClientPayment=83,
    ViewClientPayment=84,
    DeleteClientPayment=85,
    CreateNewResource=86,
    EditResource=87,
    ExportResources=88,
    ViewResource=89,
    CreateNewResourceJob=90,
    EditResourceJob=91,
    ExportResourceJobs=92,
    ViewResourceJob=93,
    DeleteResourceJob=94,
    CopyResourceJob=95,
    CreateNewPurchaseOrder=96,
    EditPurchaseOrder=97,
    ExportPurchaseOrders=98,
    ViewPurchaseOrder=99,
    DeletePurchaseOrder=100,
    CreateNewResourceInvoice=101,
    DeleteResourceInvoice=102,
    EditResourceInvoice=103,
    ExportResourceInvoices=104,
    SendResourceInvoice=105,
    ViewResourceInvoice=106,
    CreateNewResourcePayment=107,
    EditResourcePayment=108,
    ExportResourcePayments=109,
    ViewResourcePayment=110,
    DeleteResourcePayment=111,
    CreateNewTeam=112,
    EditTeam=113,
    ExportTeams=114,
    ViewTeams=115,
    DeleteTeam=116,
    CreateTeamMember=117,
    DeleteTeamMember=118,
    CreateNewChecklist=119,
    EditChecklist=120,
    ViewChecklists=121,
    DeleteChecklist=122,
    ViewClientRatings=123,
    ViewResourceRatings=124,
    EditClientRating=125,
    EditResourceRating=126,
    CreateClientCriteria=127,
    CreateResourceCriteria=128,
    DeleteClientCriteria=129,
    DeleteResourceCriteria=130,
    DeleteAbsence=131,
    EditAbsence=132,
    ChangeAbsenceStatus=133,
    ViewAbsence=134,
    CreateNewUser=135,
    EditUser=136,
    ExportUsers=137,
    ViewUsers=138,
    DeleteUser=139,
    CreateNewRole=140,
    EditRole=141,
    ViewRoles=142,
    DeleteRole=143,
    ExportRoles=144,
    CreateNewService=145,
    EditService=146,
    ExportServices=147,
    ViewServices=148,
    DeleteService=149,
    CreateNewUnit=150,
    EditUnit=151,
    ExportUnits=152,
    ViewUnits=153,
    DeleteUnit=154,
    CreateNewStatus=155,
    EditStatus=156,
    ExportStatuses=157,
    ViewStatuses=158,
    DeleteStatus=159,
    CreateNewTag=160,
    EditTag=161,
    ExportTag=162,
    ViewTag=163,
    DeleteTag=164,
    CreateNewAbsenceEntitlement=165,
    DeleteAbsenceEntitlement=166,
    EditAbsenceEntitlement=167,
    ExportEntitlements=168,
    ViewAbsenceEntitlement=169,
    ViewClientDiscount=170,
    EditClientDiscount=171,
    PushClientDiscounts=172,
    ViewResourceDiscounts=173,
    EditResourceDiscounts=174,
    PushResourceDiscounts=175,
    EditSchema=176,
    DeleteSchema=177,
    CreateSchema=178,
    CreateNewAbsence=179,
    CreatePlannedTime=229,
    AlterTime=230,
    DeletePlannedTime=231,  
    CreateTemplate=236,
    EditTemplate=237,
    ViewTemplates=238,
    DeleteTemplate=239,
    ViewProject=234,

    // plan time types
    PlanTimeTypes = 252,
    ViewPlanTimeTypes = 253,
    CreatePlanTimeType = 254,
    EditPlanTimeType = 255,
    DeletePlanTimeType = 256,

    // resource notes
    ViewEditResourceNotes = 258,

    // feedback
    EditResourceRatings = 259,
    DeleteResourceRatings = 260,
    EditClientRatings = 261,
    DeleteClientRatings = 262,

    //General Permissions for Resource Portal===
    RPHome=180,
    RPCompanyGuidelines=181,
    RPPurchaseOrders=182,
    RPInvoices=183,
    RPResources=184,
    RPEssential=185,
    RPJobs=186,
    RPTeams=187,
    RPCalendar=188,
    RPGuidelines=189,
    PurchaseOrders=190,
    Resources=191,
    RPGeneral=192,
    Invoices=193,
    RPViewJob=194,
    RPExportJobs=195,
    RPEditJob=196,
    RPViewProject=197,
    RPContactPM=198,
    RPViewTeamJobs=199,
    RPExportTeamJobs=200,
    RPUpdateAvailability=203,
    RPViewGuidelines=204,
    RPViewPurchaseOrder=205,
    RPEditPurchaseOrder=206,
    RPExportPurchaseOrders=207,
    RPCreateInvoice=208,
    RPDeleteInvoice=209,
    RPEditInvoice=210,
    RPExportInvoices=211,
    RPSendInvoices=212,
    RPViewInvoice=213,
    RPCreateResource=214,
    RPEditResource=215,
    RPExportResources=216,
    RPViewResources=217,
    RPEditLeaveRequest=218,
    RPViewClients=219,
    RPViewServices=220,
    RPEditUser=221,
    RPViewRole=222,
    RPViewStatuses=223,
    RPViewTags=224,
    RPViewUnits=225,
    RPViewLeaveRequest=226,
    RPViewAbsenceEntitlement=227, 
    RPCreateLeaveRequest=233,
    RPViewChecklist=240,
    RPAlterTime=201,
    RPDeletePlannedTime=202,
    RPCreatePlannedTime=232,
    RPCompleteJob = 242,
    CPHome = 263,
    CPViewStatements = 264,
    CPViewInvoices = 265,
    CPViewQuotes = 266,
    CPMakeRequest = 267,
    CPEditClient = 268,
    CPViewClient = 269,
    CPViewService = 270,
    CPViewTags = 271,
    CPViewClientInformation = 272,
    CPEssential = 273,
    Requests = 274,
    Languages = 275,
    Staff = 277,
    QuoteTemplates = 278,
    ClientManager = 290,
}