import {RateTypes} from "../../utills";

export class CalculateDiscountExtraChargeValues {
    discount?: number;
    extraCharge?: number;
    constructor() {
        this.discount = 0;
        this.extraCharge = 0;
    }
}
export class CalculateTotalValues extends CalculateDiscountExtraChargeValues{
    rate?: number;
    volume?: number;
    amount?: number;
    vat?: number;
    credit?: number;
    rateTypeId?: RateTypes;
    withHeldTaxAmount?:number;
    constructor() {
        super();
        this.rate = 0;
        this.volume = 0;
        this.vat = 0;
        this.credit = 0;
        this.withHeldTaxAmount=0;
        this.rateTypeId = RateTypes.Unit;
    }
}

export class CalculateTotalOptions{
    isDiscountPercentage?: boolean = true;
    isExtraChargePercentage?: boolean = true;
    isVatPercentage?: boolean = true;
}