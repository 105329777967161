import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ClientContactModel, ResponseClientData } from 'app/main/pm/clients/models';
import { ClientContactGridData } from 'app/main/pm/clients/models/client-contact-grid-data';
import { LeadContactsSearchCriteria } from 'app/main/pm/crm/models/lead-contacts-search-criteria';
import { LeadGridData } from 'app/main/pm/crm/models/lead-grid-data';
import { LeadsSearchCriteria } from 'app/main/pm/crm/models/leads-search-criteria';
import { BaseResultModel, SearchCriteriaModel, SearchResult } from 'app/models';
import { DropDownOptionsModel } from 'app/models/general';
import { AppConfigService } from 'app/services/app.settings.service';
import { CORE_SESSION_STORAGE } from 'app/services/storage/tp-storage.service.ts';
import { Constants } from 'app/shared.constants';
import { StorageService } from 'ngx-webstorage-service';
import { Observable, of, Subject, throwError } from 'rxjs';
import { map, startWith, take, tap } from 'rxjs/operators';
import { ClientId } from '../../../../services/ui-flow/clients-ui.service';
import { ResponseClientGridData } from '../../clients/models/client.model';

@Injectable({
    providedIn: 'root',
})
export class LeadService {

    private readonly _uri: string;

    private endpoint = '/client';
    private leadEndpoint = '/lead';
    private refreshRefLeadObservers$ = new Subject();
    private refreshRefLeadLock: boolean;

    constructor(
        private readonly http: HttpClient, configService: AppConfigService,
        @Inject(CORE_SESSION_STORAGE) private sessionStorage: StorageService
    ) {
        if (configService) {
            this._uri = configService.getAPIUrl();
        }
    }

    search(model: SearchCriteriaModel<LeadsSearchCriteria>): Observable<BaseResultModel<SearchResult<ResponseClientGridData>>> {
        return this.http.post<BaseResultModel<SearchResult<ResponseClientGridData>>>(
            `${this._uri}${this.endpoint}/search`,
            model,
        );
    }
    getLeadById(clientId: number): Observable<BaseResultModel<ResponseClientData>> {
        const endpoint = `${this._uri}${this.endpoint}/${clientId}`;
        return this.http.get<BaseResultModel<ResponseClientData>>(`${endpoint}`)
            .pipe(map(client => {
                return client;
            }));
    }

    getContactsForLeads(leadIds: number[], includeClientContacts = false): Observable<ClientContactGridData[]> {
        return this.searchContacts({
            showAll: true,
            searchCriteria: {
                leads: leadIds,
                includeClientContacts,
            },
        }).pipe(
            map(result => result.result),
        );
    }


    saveLead(id: number, model: any): Observable<any> {
        if (id) {
            const endpoint = `${this._uri}${this.endpoint}/${id}`;
            return this.http.put<null>(`${endpoint}`, model).pipe(map(() => ({ clientId: id })), tap(() => this.refreshRefLeads()));
        } else {
            const endpoint = `${this._uri}${this.endpoint}`;
            return this.http.post<number>(`${endpoint}`, model).pipe(map(clientId => ({ clientId })), tap(() => this.refreshRefLeads()));
        }
    }

    deleteLeadById(id: number): Observable<null> {
        const endpoint = `${this._uri}${this.endpoint}/${id}`;
        return this.http.delete<null>(`${endpoint}`).pipe(tap(() => this.refreshRefLeads()));
    }

    deleteLeadsByIds(ids: number[]): Observable<any> {
        if (ids?.length > 0) {
            const qString = `ids=${ids.join('&ids=')}`;
            const endpoint = `${this._uri}${this.endpoint}?${qString}`;
            return this.http.delete<any>(`${endpoint}`).pipe(tap(() => this.refreshRefLeads()));
        } else {
            return of();
        }
    }

    saveLeadContact(model: ClientContactModel): Observable<ClientContactModel> {
        if (model?.contactId) {
            const endpoint = `${this._uri}${this.endpoint}/${model.contactId}/contact`;
            return this.http.put<null>(`${endpoint}`, model).pipe(map(() => model));
        } else if (model) {
            model.contactId = 0;
            const endpoint = `${this._uri}${this.endpoint}/contact`;
            return this.http.post<null>(`${endpoint}`, model).pipe(map(() => model));
        } else {
            return throwError('model is falsy in LeadService.saveLeadContact');
        }
    }

    deleteLeadContactById(leadContactId: number): Observable<null> {
        return this.deleteLeadContactsByIds([leadContactId]);
    }

    deleteLeadContactsByIds(leadContactIds: number[]): Observable<null> {
        if (leadContactIds?.length > 0) {
            const qString = `ids=${leadContactIds.join('&ids=')}`;
            const endpoint = `${this._uri}${this.endpoint}/contact?${qString}`;
            return this.http.delete<any>(`${endpoint}`);
        } else {
            return of();
        }
    }

    searchContacts(model: SearchCriteriaModel<LeadContactsSearchCriteria>): Observable<SearchResult<ClientContactGridData>> {
        return this.http.post<SearchResult<ClientContactGridData>>(
            `${this._uri}${this.endpoint}/searchContacts`,
            model,
        );
    }

    getLeadContactById(leadId: number, leadContactId: number): Observable<ClientContactModel> {
        return this.getLeadById(leadId).pipe(map(lead => {
            return null;
        }));
    }

    validateLead(lead: ResponseClientData): Observable<null> {
        const endpoint = `${this._uri}${this.endpoint}/validateLead`;
        return this.http.post<null>(endpoint, lead);
    }

    validateLeadContact(leadContact: ClientContactModel): Observable<null> {
        const endpoint = `${this._uri}${this.endpoint}/validateLeadContact`;
        return this.http.post<null>(endpoint, leadContact);
    }

    getRefLeads(): Observable<DropDownOptionsModel[]> {
        return this.refreshRefLeadObservers$.pipe(startWith(undefined))
            .pipe(map(() => {
                const persistedData: string = this.sessionStorage.get(Constants.LEADS_STORAGE_KEY) as string;
                if (persistedData) {
                    return JSON.parse(persistedData) as DropDownOptionsModel[];
                } else {
                    this.refreshRefLeads();
                    return [] as DropDownOptionsModel[];
                }
            }));
    }

    refreshRefLeads(): void {
        if (this.refreshRefLeadLock) {
            return;
        } else {
            this.refreshRefLeadLock = true;
        }
    }

    convertLeadToClient(lead: ClientId): Observable<null> {
        const httpParams = new HttpParams().set('leadId', lead.clientId.toString());
        return this.http.post<null>(`${this._uri}${this.endpoint}/convertToClient`, {}, {
            params: httpParams,
        }).pipe(tap(() => this.refreshRefLeads()));
    }

    generateCompanyCode(companyName: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            responseType: 'text' as 'json',
        };
        return this.http.get<any>(`${this._uri}/api/Client/clientCode/generate?clientName=${companyName}`,
            httpOptions);
    }
    sendLeadCampaign(clientDataList: { [key: number]: string[]; }, formdata: FormData = null): Observable<BaseResultModel<boolean>> {
        const useDefaultTemplate = { customTemplate: null };
      
         const clientDataListJson = JSON.stringify(clientDataList);
        formdata.append('clientDataList', clientDataListJson);
    
        return this.http.post<BaseResultModel<boolean>>(
            this._uri + this.leadEndpoint + '/send-lead-campaign', 
            formdata
        );
    }
    
    
}