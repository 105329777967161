import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TpAuthService } from 'app/services/auth/tp-auth.service';
import { PermissionHelper } from 'app/services/helpers/global-scope/permission-helper';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { PageTypes, PermissionTypes } from "../../utills";
import { GlobalConfiguration } from "../../models/general/global-configuration";
import { ResponseUserData } from "../../main/admin/models/user.model";
import { PortalTypes } from 'app/utills/enums/portal-types';

export interface SidenavMenuItemData {
    title: string,
    pageType: PageTypes,
    icon?: string
}
export interface SidenavMenuItem {
    id: string | number,
    iconCss?: string,
    url?: string,
    modal?: boolean,
    text?: string,
    textAsync?: Observable<string>,
    items?: SidenavMenuItem[],
    showBreakBefore?: boolean
    data?: SidenavMenuItemData,
    hidden?: boolean,
    active?: boolean,
    hover?: boolean,
    badge?: number;
}

@Injectable({ providedIn: 'root' })
export class NavigationBuilderService {
    topMenuItems$: Observable<SidenavMenuItem[]>;
    bottomMenuItems$: Observable<SidenavMenuItem[]>;
    constructor(
        private readonly authService: TpAuthService,
        private readonly persmissionHelper: PermissionHelper,
        private readonly translateService: TranslateService,
        private globalConfiguration: GlobalConfiguration
    ) {
        this.buildNavigation();
    }
    buildNavigation(): void {
        this.topMenuItems$ = of(this.globalConfiguration).pipe(
            map(props => {
                const menuItems: SidenavMenuItem[] = [];
                if (props.currentUser) {
                    this._buildHomeItem(menuItems, props.currentUser);
                    if (this.authService.validatePortal(PortalTypes.Resource)) { // Resource Portal
                        this._buildResourceTabs(menuItems, props.currentUser);
                    } else if (this.authService.validatePortal(PortalTypes.Client)) { // Client Portal
                        this._buildClientProfileTab(menuItems);
                        this._buildClientRequestsTab(menuItems);
                        this._buildClientQuotesTab(menuItems);
                        this._buildClientInvoicesTab(menuItems);
                        //this._buildClientCreditsTab(menuItems);  //hide it until further notice
                    } else { // PM Portal
                        this._buildManagerQuotesTab(menuItems);
                        this._buildManagerProjectsTab(menuItems);
                        this._buildManagerRequestsTab(menuItems);
                        this._buildManagerClientsTab(menuItems);
                        this._buildManagerResourcesTab(menuItems);
                    }
                }
                return menuItems;
            })
        );
        this.bottomMenuItems$ = of(this.globalConfiguration).pipe(
            map(props => {
                const menuItems: SidenavMenuItem[] = [];
                if (props.currentUser) {
                    if (this.authService.validatePortal(PortalTypes.Resource)) { // Resource Portal
                        this.buildRequestAbsencesNav(menuItems);
                    } else if (this.authService.validatePortal(PortalTypes.Client)) { // Client Portal
                    } else { // PM Portal
                        this._buildManagerCapacityPlanner(menuItems);
                        this._buildManagerReportsTab(menuItems);
                      
                        this._buildManagerCRM(menuItems);
                        this._buildAdminTab(menuItems, false);
                        this.buildRequestAbsencesNav(menuItems);
                    }
                    this.buildUnauthorizedNav(menuItems);
                    this.buildNotFoundedNav(menuItems);
                }
                return menuItems;
            })
        );
    }

    private _buildHomeItem(menuItems: SidenavMenuItem[], user: ResponseUserData): void {
        menuItems.push({
            id: 'home',
            iconCss: 'ion ion-md-home',
            url: this.authService.redirectUser(),
            data: { title: 'Dashboard', pageType: PageTypes.Dashboard, icon: 'tp-home.png' },
            textAsync: this.translateService.stream('tp-home', { defaultText: 'Home' }) as Observable<string>,
            hidden: true
        });
    }
    public buildRequestAbsencesNav(menuItems: SidenavMenuItem[] = [], hidden: boolean = true): SidenavMenuItem {
        const sideNavItem = {
            id: 'requestAbsences',
            iconCss: 'ion ion-ios-beer',
            url: '/absences',
            data: { title: 'Absences', pageType: PageTypes.RequestAbsences },
            textAsync: this.translateService.stream('tp-absences', { defaultText: 'Absences' }) as Observable<string>,
            hidden
        }
        menuItems.push(sideNavItem);
        return sideNavItem;
    }
    private _buildManagerTabs(menuItems: SidenavMenuItem[], user: ResponseUserData): void {
        this._buildHomeItem(menuItems, user);
        this._buildManagerQuotesTab(menuItems);
        this._buildManagerProjectsTab(menuItems);
        this._buildManagerClientsTab(menuItems);
        this._buildManagerResourcesTab(menuItems);
        this._buildManagerCompanyTab(menuItems);
        this._buildManagerReportsTab(menuItems);
        this._buildAdminTab(menuItems, false);
    }
    private _buildClientTabs(menuItems: SidenavMenuItem[], user: ResponseUserData): void {
        this._buildHomeItem(menuItems, user);
        this._buildClientRequestsTab(menuItems);
        this._buildClientQuotesTab(menuItems);
        this._buildClientProjectsTab(menuItems);
    }
    private _buildResourceTabs(menuItems: SidenavMenuItem[], user: ResponseUserData): void {
        this._buildHomeItem(menuItems, user);
        menuItems.push({
            id: 'companyGuidelines', iconCss: 'ion ion-md-people',
            url: '/resource/guidelines',
            data: { title: 'Guidelines', pageType: PageTypes.ResourceCompanyGuidelines, icon: 'tp-guidelines.png' },
            textAsync: this.translateService.stream('tp-guidelines', { defaultText: 'Guidelines' }) as Observable<string>,
            hidden: !this.persmissionHelper.ValidatePermission(PermissionTypes.RPCompanyGuidelines)
        });
        menuItems.push({
            id: 'resourcePurchaseOrders',
            url: '/resource/purchase-orders',
            data: { title: 'Purchase Orders', pageType: PageTypes.ResourcePurchaseOrders, icon: 'tp-po.png' },
            iconCss: 'ion ion-md-folder',
            textAsync: this.translateService.stream('tp-pos', { defaultText: 'POs' }) as Observable<string>,
            hidden: !this.persmissionHelper.ValidatePermission(PermissionTypes.RPPurchaseOrders)
        });
        menuItems.push({
            id: 'resourceInvoices',
            url: '/resource/invoices',
            data: { title: 'Invoices', pageType: PageTypes.ResourceInvoices, icon: 'tp-invoices.png' },
            iconCss: 'ion ion-md-document',
            textAsync: this.translateService.stream('tp-invoices', { defaultText: 'Invoices' }) as Observable<string>,
            hidden: !this.persmissionHelper.ValidatePermission(PermissionTypes.RPInvoices)
        });
        menuItems.push({
            id: 'resourceResources',
            url: '/resource/resources',
            data: { title: 'Resources', pageType: PageTypes.Resources, icon: 'tp-resources.png' },
            iconCss: 'ion ion-md-people',
            textAsync: this.translateService.stream('tp-resources', { defaultText: 'Resources' }) as Observable<string>,
            hidden: !this.persmissionHelper.ValidatePermission(PermissionTypes.RPResources)
        });
        menuItems.push({
            id: 'resourceCapacityPlanner',
            textAsync: this.translateService.stream('tp-planner', { defaultText: 'Planner' }) as Observable<string>,
            url: '/resource/capacity-planner',
            data: { title: 'Capacity Planner', pageType: PageTypes.ResourcePortalCapacityPlanner, icon: 'tp-planner.png' },
            hidden: !this.persmissionHelper.ValidatePermission(PermissionTypes.RPDisplayPlannerTab)
        });
        menuItems.push({
            id: 'project',
            url: '/resource/project/:id',
            data: { title: 'Project', pageType: PageTypes.Project, icon: 'tp-projects.png' },
            iconCss: 'ion ion-md-flask',
            textAsync: this.translateService.stream('tp-project', { defaultText: 'Project' }) as Observable<string>,
            hidden: true
        });
    }

    private _buildManagerAppsTab(menuItems: SidenavMenuItem[]): void {
        menuItems.push({
            id: 'apps',
            url: '/pm/admin/integrations',
            modal: true,
            data: { title: 'Apps', pageType: PageTypes.Apps, icon: 'tp-apps.png' },
            iconCss: 'ion ion-md-apps',
            textAsync: this.translateService.stream('tp-apps', { defaultText: 'Apps' }) as Observable<string>,
        });
    }
    private _buildManagerProductsTab(menuItems: SidenavMenuItem[]): void {
        menuItems.push({
            id: 'products',
            url: '/products',
            data: { title: 'Products Coming Soon', pageType: PageTypes.Products, icon: 'tp-products.png' },
            iconCss: 'ion ion-md-apps',
            textAsync: this.translateService.stream('tp-products', { defaultText: 'Products' }) as Observable<string>,
        });
    }
    private _buildManagerQuotesTab(menuItems: SidenavMenuItem[]): void {
        menuItems.push(this.buildManagerQuotesNav());
    }
    public buildManagerQuotesNav(hidden: boolean = false, items: SidenavMenuItem[] = []): SidenavMenuItem {
        return {
            id: 'quotes',
            url: '/pm/quotes',
            iconCss: 'ion ion-md-document',
            data: { title: 'Quotes', pageType: PageTypes.Quotes, icon: 'tp-quotes.png' },
            textAsync: this.translateService.stream('tp-quotes-name', { defaultText: 'Quotes' }) as Observable<string>,
            hidden: !this.persmissionHelper.ValidatePermission(PermissionTypes.DisplayQuotesTab),
            items
        }
    }
    private _buildManagerProjectsTab(menuItems: SidenavMenuItem[], includeChildren: boolean = true): void {
        menuItems.push(this.buildManagerProjectsNav());
        menuItems.push({
            id: 'project',
            url: '/pm/projects/:id/edit',
            data: { title: 'Project', pageType: PageTypes.Project, icon: 'tp-projects.png' },
            iconCss: 'ion ion-md-flask',
            textAsync: this.translateService.stream('tp-project', { defaultText: 'Project' }) as Observable<string>,
            hidden: true
        });
    }
    public buildManagerProjectsNav(items: SidenavMenuItem[] = []): SidenavMenuItem {
        return {
            id: 'projects',
            url: '/pm/projects',
            data: { title: 'Projects', pageType: PageTypes.Projects, icon: 'tp-projects.png' },
            iconCss: 'ion ion-md-flask',
            textAsync: this.translateService.stream('tp-projects', { defaultText: 'Projects' }) as Observable<string>,
            items,
            hidden: !this.persmissionHelper.ValidatePermission(PermissionTypes.DisplayProjectsTab)
        }
    }
    private _buildManagerProjectsTabChildItems(): SidenavMenuItem[] {
        const childItems: SidenavMenuItem[] = [];
        childItems.push({
            id: 'myProjects',
            textAsync: this.translateService.stream('tp-all-projects', { defaultText: 'All Projects' }) as Observable<string>,
            url: '/pm/projects',
            data: { title: 'Projects', pageType: PageTypes.Projects, icon: 'tp-projects.png' },
            hidden: !this.persmissionHelper.ValidatePermission(PermissionTypes.AllProjects)
        });
        this._buildManagerProjectsTemplates(childItems);
        return childItems;
    }
    private _buildManagerRequestsTab(menuItems: SidenavMenuItem[]): void {
        if ((this.globalConfiguration.companyBranchSetting.enableClientRequestFlow ?? false) == true) {
            menuItems.push({
                id: 'requests',
                url: '/pm/requests',
                data: { title: 'Requests', pageType: PageTypes.Requests, icon: 'tp-requests.png' },
                iconCss: 'ion ion-md-flask',
                textAsync: this.translateService.stream('tp-requests-name', { defaultText: 'Requests' }) as Observable<string>,
                items: [],
                hidden: !this.persmissionHelper.ValidatePermission(PermissionTypes.Requests),
            });
        }
    }

    private _buildManagerCapacityPlanner(menuItems: SidenavMenuItem[]): void {
        menuItems.push({
            id: 'capacityPlanner',
            textAsync: this.translateService.stream('tp-planner', { defaultText: 'Planner' }) as Observable<string>,
            url: '/pm/capacity-planner',
            data: { title: 'Capacity Planner', pageType: PageTypes.CapacityPlanner, icon: 'tp-planner.png' },
            items: this._buildManagerCapacityPlannerChildItems(),
            hidden: !this.persmissionHelper.ValidatePermissions([PermissionTypes.DisplayPlannerTab, PermissionTypes.PlanTimeTypes])
        });
    }
    private _buildManagerCapacityPlannerChildItems(): SidenavMenuItem[] {
        const childItems: SidenavMenuItem[] = [];
        childItems.push({
            id: 'capacityPlanner',
            textAsync: this.translateService.stream('tp-capacity-planner', { defaultText: 'Capacity Planner' }) as Observable<string>,
            url: '/pm/capacity-planner',
            data: { title: 'Capacity Planner', pageType: PageTypes.CapacityPlanner, icon: 'tp-planner.png' },
            hidden: !this.persmissionHelper.ValidatePermission(PermissionTypes.DisplayPlannerTab)
        });
        childItems.push({
            id: 'capacityPlanner',
            textAsync: this.translateService.stream('tp-plan-time-types', { defaultText: 'Plan Time Types' }) as Observable<string>,
            url: '/pm/capacity-planner/plan-time-types',
            data: { title: 'Plan Time Types', pageType: PageTypes.PlanTimeTypes, icon: 'tp-planner.png' },
            hidden: !this.persmissionHelper.ValidatePermission(PermissionTypes.PlanTimeTypes)
        });
        return childItems;
    }
    public buildManagerLeadssNav(items: SidenavMenuItem[] = []): SidenavMenuItem {
        return {
            id: 'leads',
            url: '/pm/crm/leads',
            data: { title: 'Leads', pageType: PageTypes.AllLeads, icon: 'tp-leads.png' },
            iconCss: 'ion ion-md-person',
            textAsync: this.translateService.stream('tp-leads', { defaultText: 'Leads' }) as Observable<string>,
            items,
            hidden: !this.persmissionHelper.ValidatePermission(PermissionTypes.DisplayCRMTab)
        }
    }
    private _buildManagerCRM(menuItems: SidenavMenuItem[]): void {
        menuItems.push({
            id: 'crm',
            textAsync: this.translateService.stream('tp-crm', { defaultText: 'CRM' }) as Observable<string>,
            url: '/pm/crm/leads',
            data: { title: 'CRM', pageType: PageTypes.AllLeads, icon: 'tp-crm.png' },
            items: this._buildManagerCRMChildItems(),
            hidden: !this.persmissionHelper.ValidatePermissions([PermissionTypes.DisplayCRMTab])
        });
    }
    private _buildManagerCRMChildItems(): SidenavMenuItem[] {
        const childItems: SidenavMenuItem[] = [];
        childItems.push({
            id: 'crm',
            textAsync: this.translateService.stream('tp-crm', { defaultText: 'CRM' }) as Observable<string>,
            url: '/pm/crm/leads',
            data: { title: 'CRM', pageType: PageTypes.AllLeads, icon: 'tp-crm.png' },
            hidden: !this.persmissionHelper.ValidatePermissions([PermissionTypes.DisplayCRMTab])
        });
        return childItems;
    }
    private _buildManagerProjectsTemplates(menuItems: SidenavMenuItem[]): void {
        menuItems.push({
            id: 'projectTemplates',
            textAsync: this.translateService.stream('tp-project-templates', { defaultText: 'Project Templates' }) as Observable<string>,
            url: '/pm/projects/templates',
            data: { title: 'Project Templates', pageType: PageTypes.Templates, icon: 'tp-templates.png' },
            hidden: !this.persmissionHelper.ValidatePermission(PermissionTypes.DisplayTemplatesTab)
        });
    }

    private _buildManagerClientsTab(menuItems: SidenavMenuItem[]): void {
        menuItems.push(this.buildManagerClientsNav(this._buildManagerClientsTabChildItems()));
    }
    public buildManagerClientsNav(items: SidenavMenuItem[] = []): SidenavMenuItem {
        return {
            id: 'clients',
            url: '/pm/clients',
            data: { title: 'Clients', pageType: PageTypes.Clients, icon: 'tp-clients.png' },
            iconCss: 'ion ion-md-person',
            textAsync: this.translateService.stream('tp-clients', { defaultText: 'Clients' }) as Observable<string>,
            items,
            hidden: !this.persmissionHelper.ValidatePermission(PermissionTypes.DisplayClientsTab)
        }
    }
    private _buildManagerClientsTabChildItems(): SidenavMenuItem[] {
        const childItems: SidenavMenuItem[] = [];
        childItems.push({
            id: 'clients',
            textAsync: this.translateService.stream('tp-all-clients', { defaultText: 'All Clients' }) as Observable<string>,
            url: '/pm/clients', data: { title: 'clients', pageType: PageTypes.Clients, icon: 'tp-clients.png' },
            hidden: !this.persmissionHelper.ValidatePermission(PermissionTypes.AllClients)
        });
        childItems.push({
            id: 'clientJobs',
            textAsync: this.translateService.stream('tp-all-client-jobs', { defaultText: 'All Client Jobs' }) as Observable<string>,
            url: '/pm/projects/clientjobs',
            data: { title: 'Client Jobs', pageType: PageTypes.ClientJobs },
            hidden: !this.persmissionHelper.ValidatePermission(PermissionTypes.ViewClientJob)
        });
        childItems.push({
            id: 'clientInvoices',
            textAsync: this.translateService.stream('tp-all-client-invoices', { defaultText: 'All Client Invoices' }) as Observable<string>,
            url: '/pm/clientInvoices',
            data: { title: 'Client Invoices', pageType: PageTypes.ClientInvoices, icon: 'tp-invoices.png' },
            hidden: !this.persmissionHelper.ValidatePermission(PermissionTypes.ViewClientInvoice)
        });
        childItems.push({
            id: 'clientPayments',
            textAsync: this.translateService.stream('tp-all-client-payments', { defaultText: 'All Client Payments' }) as Observable<string>,
            url: '/pm/clientPayments',
            data: { title: 'Client Payments', pageType: PageTypes.ClientPayments, icon: 'tp-payments.png' },
            hidden: !this.persmissionHelper.ValidatePermission(PermissionTypes.ViewClientPayment)
        });
        childItems.push({
            id: 'clientContacts',
            textAsync: this.translateService.stream('tp-all-client-contacts', { defaultText: 'All Client Contacts' }) as Observable<string>,
            url: '/pm/clientContacts',
            data: { title: 'Client Contacts', pageType: PageTypes.ClientContacts, icon: 'tp-clients.png' },
            hidden: !this.persmissionHelper.ValidatePermission(PermissionTypes.ViewClient)
        });
        return childItems;
    }

    private _buildManagerResourcesTab(menuItems: SidenavMenuItem[]): void {
        menuItems.push(this.buildManagerResourcesNav(this._buildManagerResourcesTabChildItems()));
    }
    public buildManagerResourcesNav(items: SidenavMenuItem[] = []): SidenavMenuItem {
        return {
            id: 'resources',
            url: '/pm/resources',
            data: { title: 'Resources', pageType: PageTypes.Resources, icon: 'tp-resources.png' },
            iconCss: 'ion ion-md-people',
            textAsync: this.translateService.stream('tp-resources', { defaultText: 'Resources' }) as Observable<string>,
            items,
            hidden: !this.persmissionHelper.ValidatePermission(PermissionTypes.DisplayResourcesTab)
        }
    }
    private _buildManagerResourcesTabChildItems(): SidenavMenuItem[] {
        const childItems: SidenavMenuItem[] = [];
        childItems.push({
            id: 'allResources',
            textAsync: this.translateService.stream('tp-all-resources', { defaultText: 'All Resources' }) as Observable<string>,
            url: '/pm/resources',
            data: { title: 'Resources', pageType: PageTypes.Resources, icon: 'tp-resources.png' },
            hidden: !this.persmissionHelper.ValidatePermission(PermissionTypes.AllResources)
        });
        childItems.push({
            id: 'resourceJobs',
            textAsync: this.translateService.stream('tp-all-resource-jobs', { defaultText: 'All Resource Jobs' }) as Observable<string>,
            url: '/pm/projects/resourcejobs',
            data: { title: 'Resource Jobs', pageType: PageTypes.ResourceJobs },
            hidden: !this.persmissionHelper.ValidatePermission(PermissionTypes.AllResourceJobs)
        });
        childItems.push({
            id: 'purchaseOrders',
            textAsync: this.translateService.stream('tp-all-purchase-orders', { defaultText: 'All Purchase Orders' }) as Observable<string>,
            url: '/pm/purchaseOrders',
            data: { title: 'Purchase Orders', pageType: PageTypes.ResourcePurchaseOrders, icon: 'tp-po.png' },
            hidden: !this.persmissionHelper.ValidatePermission(PermissionTypes.AllPurchaseOrders)
        });
        childItems.push({
            id: 'resourceInvoices',
            textAsync: this.translateService.stream('tp-all-resource-invoices', { defaultText: 'All Resource Invoices' }) as Observable<string>,
            url: '/pm/resourceInvoices',
            data: { title: 'Resource Invoices', pageType: PageTypes.ResourceInvoices, icon: 'tp-invoices.png' },
            hidden: !this.persmissionHelper.ValidatePermission(PermissionTypes.AllResourceInvoices)
        });
        childItems.push({
            id: 'resourcePayments',
            textAsync: this.translateService.stream('tp-all-resource-payments', { defaultText: 'All Resource Payments' }) as Observable<string>,
            url: '/pm/resourcePayments',
            data: { title: 'Resource Payments', pageType: PageTypes.ResourcePayments, icon: 'tp-payments.png' },
            hidden: !this.persmissionHelper.ValidatePermission(PermissionTypes.AllResourcePayments)
        });
        return childItems;
    }

    private _buildManagerCompanyTab(menuItems: SidenavMenuItem[]): void {
        menuItems.push({
            id: 'company',
            url: '/pm/absences',
            data: { title: 'Absences', pageType: PageTypes.Absences },
            iconCss: 'ion ion-md-contacts',
            textAsync: this.translateService.stream('tp-absence-management', { defaultText: 'Absence Management' }) as Observable<string>,
            items: this._buildManagerCompanyTabChildItems(),
            hidden: !this.persmissionHelper.ValidatePermissions([PermissionTypes.Absences, PermissionTypes.AbsenceEntitlement, PermissionTypes.AbsenceTypes, PermissionTypes.PublicHolidays])
        });
    }
    private _buildManagerCompanyTabChildItems(): SidenavMenuItem[] {
        const childItems: SidenavMenuItem[] = [];
        childItems.push({
            id: 'absences',
            textAsync: this.translateService.stream('tp-absences', { defaultText: 'Absences' }) as Observable<string>,
            url: '/pm/admin/absences', data: { title: 'Absences', pageType: PageTypes.Absences },
            hidden: !this.persmissionHelper.ValidatePermission(PermissionTypes.Absences)
        })
        childItems.push({
            id: 'absenceEntitlements',
            textAsync: this.translateService.stream('tp-absence-entitlements', { defaultText: 'Absence Entitlements' }) as Observable<string>,
            url: '/pm/admin/absence-entitlements',
            data: { title: 'Absence Entitlements', pageType: PageTypes.AbsenceEntitlements },
            hidden: !this.persmissionHelper.ValidatePermission(PermissionTypes.AbsenceEntitlement)
        })
        childItems.push({
            id: 'absenceTypes',
            textAsync: this.translateService.stream('tp-absence-types', { defaultText: 'Absence Types' }) as Observable<string>,
            url: '/pm/admin/absence-types',
            data: { title: 'Absence Types', pageType: PageTypes.AbsenceTypes },
            hidden: !this.persmissionHelper.ValidatePermission(PermissionTypes.AbsenceTypes)
        });
        childItems.push({
            id: 'publicHolidays',
            textAsync: this.translateService.stream('tp-public-holidays', { defaultText: 'Public Holidays' }) as Observable<string>,
            url: '/pm/admin/public-holidays',
            data: { title: 'Public Holidays', pageType: PageTypes.PublicHolidays },
            hidden: !this.persmissionHelper.ValidatePermission(PermissionTypes.PublicHolidays)
        });
        return childItems;
    }

    private _buildManagerReportsTab(menuItems: SidenavMenuItem[]): void {
        menuItems.push({
            id: 'reports',
            url: '/pm/reports',
            data: { title: 'Reports', pageType: PageTypes.Reports, icon: 'tp-reports.png' },
            iconCss: 'ion ion-md-analytics',
            textAsync: this.translateService.stream('tp-reports', { defaultText: 'Reports' }) as Observable<string>,
            items: this._buildManagerReportsTabChildItems(),
            hidden: !this.persmissionHelper.ValidatePermission(PermissionTypes.DisplayReportsTab)
        });
    }
    private _buildManagerReportsTabChildItems(): SidenavMenuItem[] {
        const childItems: SidenavMenuItem[] = [];
        childItems.push(this.buildProfitVsLossReportNav());
        childItems.push(this.buildProfitReportNav());
        childItems.push(this.buildSalesReportNav());
        childItems.push(this.buildQuotesReportNav());
        childItems.push(this.buildClientPaymentReportNav());
        childItems.push(this.buildResourcePaymentReportNav());
        childItems.push(this.buildUtilizationReportNav());
        childItems.push(this.buildProductivityReportNav());
        return childItems;
    }
    private _buildManagerOldReportsItems(): SidenavMenuItem[] {
        const childItems: SidenavMenuItem[] = [];
        childItems.push({
            id: 'quoteReports',
            textAsync: this.translateService.stream('tp-quotes-name', { defaultText: 'Quotes' }) as Observable<string>,
            url: '/pm/reports/quotes-report',
            data: { title: 'Quote Insights', pageType: PageTypes.QuoteReports, icon: 'tp-reports.png' },
            hidden: false
        });
        childItems.push({
            id: 'quoteWonReports',
            textAsync: this.translateService.stream('tp-quotes-won', { defaultText: 'Quotes Won' }) as Observable<string>,
            url: '/pm/reports/quotes-report/won',
            data: { title: 'Quote Won Reports', pageType: PageTypes.QuoteReports, icon: 'tp-reports.png' },
            hidden: true
        });
        childItems.push({
            id: 'quoteTotalReports',
            textAsync: this.translateService.stream('tp-quotes-total', { defaultText: 'Quotes Total' }) as Observable<string>,
            url: '/pm/reports/quotes-report/total',
            data: { title: 'Quote Total Reports', pageType: PageTypes.QuoteReports, icon: 'tp-reports.png' },
            hidden: true
        });
        childItems.push({
            id: 'quoteMostPopularServiceReports',
            textAsync: this.translateService.stream('tp-quotes-most-popular-service', { defaultText: 'Quotes Most Popular Service' }) as Observable<string>,
            url: '/pm/reports/quotes-report/most-popular-service',
            data: { title: 'Quote Most Popular Service Reports', pageType: PageTypes.QuoteReports, icon: 'tp-reports.png' },
            hidden: true
        });
        childItems.push({
            id: 'quoteTotalVolumeReports',
            textAsync: this.translateService.stream('tp-quotes-total-volume', { defaultText: 'Quotes Total Volume' }) as Observable<string>,
            url: '/pm/reports/quotes-report/total-volume',
            data: { title: 'Quote Total Volume Reports', pageType: PageTypes.QuoteReports, icon: 'tp-reports.png' },
            hidden: true
        });
        childItems.push({
            id: 'projectReports',
            textAsync: this.translateService.stream('tp-projects', { defaultText: 'Projects' }) as Observable<string>,
            url: '/pm/reports/projects-report',
            data: { title: 'Project Reports', pageType: PageTypes.ProjectReports, icon: 'tp-reports.png' },
            hidden: false
        });
        childItems.push({
            id: 'projectDeliveredReports',
            textAsync: this.translateService.stream('tp-projects-delivered', { defaultText: 'Projects Delivered' }) as Observable<string>,
            url: '/pm/reports/projects-report/delivered',
            data: { title: 'Project Delivered Reports', pageType: PageTypes.ProjectReports, icon: 'tp-reports.png' },
            hidden: true
        });
        childItems.push({
            id: 'projectLongestDurationReports',
            textAsync: this.translateService.stream('tp-projects-longest-duration', { defaultText: 'Projects Longest Duration' }) as Observable<string>,
            url: '/pm/reports/projects-report/longest-duration',
            data: { title: 'Project Longest Duration Reports', pageType: PageTypes.ProjectReports, icon: 'tp-reports.png' },
            hidden: true
        });
        childItems.push({
            id: 'projectAverageClientJobReports',
            textAsync: this.translateService.stream('tp-average-client-job', { defaultText: 'Average Client Job' }) as Observable<string>,
            url: '/pm/reports/projects-report/average-client-job',
            data: { title: 'Average Client Job Reports', pageType: PageTypes.ProjectReports, icon: 'tp-reports.png' },
            hidden: true
        });
        childItems.push({
            id: 'projectAverageResourceJobReports',
            textAsync: this.translateService.stream('tp-average-resource-job', { defaultText: 'Average Resource Job' }) as Observable<string>,
            url: '/pm/reports/projects-report/average-resource-job',
            data: { title: 'Average Resource Job Reports', pageType: PageTypes.ProjectReports, icon: 'tp-reports.png' },
            hidden: true
        });
        childItems.push({
            id: 'projectAverageProfitReports',
            textAsync: this.translateService.stream('tp-projects-average-profit', { defaultText: 'Projects Average Profit' }) as Observable<string>,
            url: '/pm/reports/projects-report/average-profit',
            data: { title: 'Project Average Profit Reports', pageType: PageTypes.ProjectReports, icon: 'tp-reports.png' },
            hidden: true
        });
        childItems.push({
            id: 'revenueReport',
            textAsync: this.translateService.stream('tp-revenue', { defaultText: 'Revenue' }) as Observable<string>,
            url: '/pm/reports/revenue-report',
            data: { title: 'Revenue Reports', pageType: PageTypes.RevenueReports, icon: 'tp-reports.png' },
            hidden: false
        });
        childItems.push({
            id: 'profitReport',
            textAsync: this.translateService.stream('tp-profit', { defaultText: 'Profit' }) as Observable<string>,
            url: '/pm/reports/profit-report',
            data: { title: 'Profit Reports', pageType: PageTypes.ProfitReports, icon: 'tp-reports.png' },
            hidden: false
        });
        return childItems;
    }

    public buildProfitVsLossReportNav(hidden: boolean = true, items: SidenavMenuItem[] = []): SidenavMenuItem {
        return {
            id: 'profitVsLoss',
            textAsync: this.translateService.stream('tp-profit-vs-loss', { defaultText: 'Profit Vs Loss' }) as Observable<string>,
            url: '/pm/reports/profit-vs-loss',
            data: { title: 'Reports', pageType: PageTypes.ProfitVsLossReports, icon: 'tp-reports.png' },
            items,
            hidden
        };
    }
    public buildProfitReportNav(hidden: boolean = false, items: SidenavMenuItem[] = []): SidenavMenuItem {
        return {
            id: 'profit',
            textAsync: this.translateService.stream('tp-profit', { defaultText: 'Profit' }) as Observable<string>,
            url: '/pm/reports/profit',
            data: { title: 'Reports', pageType: PageTypes.ProfitReports, icon: 'tp-reports.png' },
            items,
            hidden
        };
    }
    public buildServicesReportNav(hidden: boolean = false, items: SidenavMenuItem[] = []): SidenavMenuItem {
        return {
            id: 'services',
            textAsync: this.translateService.stream('tp-services', { defaultText: 'Services' }) as Observable<string>,
            url: '/pm/reports/services',
            data: { title: 'Reports', pageType: PageTypes.ServicesReports, icon: 'tp-reports.png' },
            items,
            hidden
        };
    }
    public buildSalesReportNav(hidden: boolean = false, items: SidenavMenuItem[] = []): SidenavMenuItem {
        return {
            id: 'sales',
            textAsync: this.translateService.stream('tp-sales', { defaultText: 'Sales' }) as Observable<string>,
            url: '/pm/reports/sales',
            data: { title: 'Reports', pageType: PageTypes.SalesReports, icon: 'tp-reports.png' },
            items,
            hidden
        };
    }
    public buildQuotesReportNav(hidden: boolean = false, items: SidenavMenuItem[] = []): SidenavMenuItem {
        return {
            id: 'sales',
            textAsync: this.translateService.stream('tp-quotes-name', { defaultText: 'Quotes' }) as Observable<string>,
            url: '/pm/reports/quotes',
            data: { title: 'Reports', pageType: PageTypes.QuoteReports, icon: 'tp-reports.png' },
            items,
            hidden
        };
    }
    public buildClientPaymentReportNav(hidden: boolean = false, items: SidenavMenuItem[] = []): SidenavMenuItem {
        return {
            id: 'client-payment',
            textAsync: this.translateService.stream('tp-client-payments', { defaultText: 'Client Payments' }) as Observable<string>,
            url: '/pm/reports/client-payment',
            data: { title: 'Reports', pageType: PageTypes.ClientPaymentReports, icon: 'tp-reports.png' },
            items,
            hidden
        };
    }
    public buildResourcePaymentReportNav(hidden: boolean = false, items: SidenavMenuItem[] = []): SidenavMenuItem {
        return {
            id: 'resource-payment',
            textAsync: this.translateService.stream('tp-resource-payments', { defaultText: 'Resource Payments' }) as Observable<string>,
            url: '/pm/reports/resource-payment',
            data: { title: 'Reports', pageType: PageTypes.ResourcePaymentReports, icon: 'tp-reports.png' },
            items,
            hidden
        };
    }
    public buildUtilizationReportNav(hidden: boolean = false, items: SidenavMenuItem[] = []): SidenavMenuItem {
        return {
            id: 'utilization-report',
            textAsync: this.translateService.get('tp-utilization', { defaultText: 'Utilization' }) as Observable<string>,
            url: '/pm/reports/utilization',
            data: { title: 'Reports', pageType: PageTypes.UtilizationReports, icon: 'tp-reports.png' },
            items,
            hidden
        };
    }
    public buildProductivityReportNav(hidden: boolean = false, items: SidenavMenuItem[] = []): SidenavMenuItem {
        return {
            id: 'productivity-report',
            textAsync: this.translateService.get('tp-productivity', { defaultText: 'Productivity' }) as Observable<string>,
            url: '/pm/reports/productivity',
            data: { title: 'Reports', pageType: PageTypes.ProductivityReports, icon: 'tp-reports.png' },
            items,
            hidden
        };
    }
    public buildUnauthorizedNav(menuItems: SidenavMenuItem[] = [], uri: string = '', hidden: boolean = true): SidenavMenuItem {
        const sideNavItem = {
            id: 'unauthorized',
            url: uri + '/unauthorized',
            data: { title: 'Unauthorized', pageType: PageTypes.Unauthorized },
            iconCss: 'ion ion-md-settings',
            textAsync: this.translateService.stream('tp-unauthorized', { defaultText: 'Unauthorized' }) as Observable<string>,
            hidden
        };
        menuItems.push(sideNavItem);
        return sideNavItem;
    }
    public buildNotFoundedNav(menuItems: SidenavMenuItem[] = [], hidden: boolean = true): SidenavMenuItem {
        const sideNavItem = {
            id: 'notFounded',
            url: '/notFounded',
            data: { title: 'Not Founded', pageType: PageTypes.NotFounded },
            iconCss: 'ion ion-md-settings',
            textAsync: this.translateService.stream('tp-not-founded', { defaultText: 'Not Founded' }) as Observable<string>,
            hidden
        };
        menuItems.push(sideNavItem);
        return sideNavItem;
    }
    private _buildManagerUsersTab(menuItems: SidenavMenuItem[]): void {
        menuItems.push({
            id: 'adminUsers',
            textAsync: this.translateService.stream('tp-people-&-permissions', { defaultText: 'People & Permissions' }) as Observable<string>,
            url: '/pm/admin/users',
            data: { title: 'Users', pageType: PageTypes.Users },
            items: this._buildManagerUsersTabChildItems(),
            hidden: !this.persmissionHelper.ValidatePermissions([PermissionTypes.Users, PermissionTypes.Roles])
        });
    }
    private _buildManagerUsersTabChildItems(): SidenavMenuItem[] {
        const childItems: SidenavMenuItem[] = [];
        childItems.push({
            id: 'allUsers',
            textAsync: this.translateService.stream('tp-users', { defaultText: 'Users' }) as Observable<string>,
            url: '/pm/admin/users',
            data: { title: 'Users', pageType: PageTypes.Users },
            hidden: !this.persmissionHelper.ValidatePermission(PermissionTypes.Users)
        });
        childItems.push({
            id: 'roles',
            url: '/pm/admin/roles',
            data: { title: 'Roles', pageType: PageTypes.Roles },
            textAsync: this.translateService.stream('tp-roles', { defaultText: 'Roles' }) as Observable<string>,
            hidden: !this.persmissionHelper.ValidatePermission(PermissionTypes.Roles)
        });
        childItems.push({
            id: 'staff',
            url: '/pm/admin/staff',
            data: { title: 'Staff', pageType: PageTypes.Staff },
            textAsync: this.translateService.stream('tp-staff', { defaultText: 'Staff' }) as Observable<string>,
            hidden: !this.persmissionHelper.ValidatePermission(PermissionTypes.Staff)
        });
        childItems.push({
            id: 'role',
            url: '/pm/admin/roles/:id/edit',
            data: { title: 'Role', pageType: PageTypes.Role },
            iconCss: 'ion ion-md-flask',
            textAsync: this.translateService.stream('tp-role', { defaultText: 'Role' }) as Observable<string>,
            hidden: true
        });
        childItems.push({
            id: 'teams',
            textAsync: this.translateService.stream('tp-teams', { defaultText: 'Teams' }) as Observable<string>,
            url: '/pm/admin/resource-teams',
            data: { title: 'Teams', pageType: PageTypes.Teams },
            hidden: !this.persmissionHelper.ValidatePermission(PermissionTypes.Teams)
        });
        return childItems;
    }

    private _buildManagerSystemSettingsTab(menuItems: SidenavMenuItem[]): void {
        menuItems.push({
            id: 'systemSettings',
            textAsync: this.translateService.stream('tp-system', { defaultText: 'System' }) as Observable<string>,
            url: '/pm/admin/system-settings',
            data: { title: 'System Settings', pageType: PageTypes.SystemSettings, icon: 'tp-settings.png' },
            items: this._buildManagerSystemSettingsTabChildItems(),
            hidden: !this.persmissionHelper.ValidatePermissions([PermissionTypes.CompanySettings, PermissionTypes.FinancialSettings, PermissionTypes.Services, PermissionTypes.Units, PermissionTypes.Statuses, PermissionTypes.Numbering, PermissionTypes.Tags])
        });
    }
    private _buildManagerSystemSettingsTabChildItems(): SidenavMenuItem[] {
        const childItems: SidenavMenuItem[] = [];
        childItems.push({
            id: 'adminFinanceSettings',
            textAsync: this.translateService.stream('tp-company-settings', { defaultText: 'Company Settings' }) as Observable<string>,
            url: '/pm/admin/system-settings',
            data: { title: 'System Settings', pageType: PageTypes.SystemSettings, icon: 'tp-settings.png' },
            hidden: !this.persmissionHelper.ValidatePermission(PermissionTypes.CompanySettings)
        });
        childItems.push({
            id: 'services',
            textAsync: this.translateService.stream('tp-services', { defaultText: 'Services' }) as Observable<string>,
            url: '/pm/admin/services',
            data: { title: 'Services', pageType: PageTypes.Services },
            hidden: !this.persmissionHelper.ValidatePermission(PermissionTypes.Services)
        });
        childItems.push({
            id: 'workflows',
            textAsync: this.translateService.stream('tp-workflows', { defaultText: 'Workflows' }) as Observable<string>,
            url: '/pm/admin/workflows',
            data: { title: 'WorkFlows', pageType: PageTypes.Workflows },
            hidden: !this.persmissionHelper.ValidatePermission(PermissionTypes.Services)
        });
        childItems.push({
            id: 'messages',
            textAsync: this.translateService.stream('tp-announcements', { defaultText: 'Announcements', }) as Observable<string>,
            url: '/pm/admin/announcements',
            data: { title: 'Announcements', pageType: PageTypes.Announcements },
            hidden: !this.persmissionHelper.ValidatePermission(PermissionTypes.CompanySettings)
        })
        childItems.push({
            id: 'quote-templates',
            textAsync: this.translateService.stream('tp-quote-templates', { defaultText: 'Quote Templates', }) as Observable<string>,
            url: '/pm/admin/quote-templates',
            data: { title: 'Quote Templates', pageType: PageTypes.QuoteTemplates },
            hidden: !this.persmissionHelper.ValidatePermission(PermissionTypes.QuoteTemplates)
        })
        childItems.push({
            id: 'units',
            textAsync: this.translateService.stream('tp-units', { defaultText: 'Units' }) as Observable<string>,
            url: '/pm/admin/units',
            data: { title: 'Units', pageType: PageTypes.Units },
            hidden: !this.persmissionHelper.ValidatePermission(PermissionTypes.Units)
        });
        childItems.push({
            id: 'languages',
            textAsync: this.translateService.stream('tp-languages', { defaultText: 'Languages' }) as Observable<string>,
            url: '/pm/admin/languages',
            data: { title: 'Languages', pageType: PageTypes.Languages },
            hidden: !this.persmissionHelper.ValidatePermission(PermissionTypes.Languages)
        });
        childItems.push({
            id: 'statusesKeywordsAndTags',
            textAsync: this.translateService.stream('tp-statuses', { defaultText: 'Statuses' }) as Observable<string>,
            url: '/pm/admin/statuses',
            data: { title: 'Status Settings', pageType: PageTypes.StatusSettings },
            hidden: !this.persmissionHelper.ValidatePermission(PermissionTypes.Statuses)
        });
        childItems.push({
            id: 'numbering',
            textAsync: this.translateService.stream('tp-numbering', { defaultText: 'Numbering' }) as Observable<string>,
            url: '/pm/admin/numbering',
            data: { title: 'Numbering', pageType: PageTypes.Numbering },
            hidden: !this.persmissionHelper.ValidatePermission(PermissionTypes.Numbering)
        });
        childItems.push({
            id: 'tags',
            textAsync: this.translateService.stream('tp-tags', { defaultText: 'Tags' }) as Observable<string>,
            url: '/pm/admin/tags',
            data: { title: 'Tags', pageType: PageTypes.Tags },
            hidden: !this.persmissionHelper.ValidatePermission(PermissionTypes.Tags)
        });
        return childItems;
    }
    private _buildManagerTemplatesTab(menuItems: SidenavMenuItem[]): void {
        menuItems.push({
            id: 'templates',
            textAsync: this.translateService.stream('tp-templates', { defaultText: 'Templates' }) as Observable<string>,
            url: '/pm/admin/paperwork-templates/quotes',
            data: { title: 'Quote Templates', pageType: PageTypes.QuotePaperworkTemplates, icon: 'tp-templates.png' },
            items: this._buildManagerTemplatesTabChildItems(),
            hidden: !this.persmissionHelper.ValidatePermissions([PermissionTypes.QuotePaperworkTemplates, PermissionTypes.InvoiceTemplates, PermissionTypes.POTemplates])
        });
    }
    private _buildManagerTemplatesTabChildItems(): SidenavMenuItem[] {
        const childItems: SidenavMenuItem[] = [];
        childItems.push({
            id: 'quoteTemplates', textAsync: this.translateService.stream('tp-quote', { defaultText: 'Quote' }) as Observable<string>,
            url: '/pm/admin/paperwork-templates/quotes',
            data: { title: 'Quote Templates', pageType: PageTypes.QuotePaperworkTemplates, icon: 'tp-templates.png' },
            hidden: !this.persmissionHelper.ValidatePermission(PermissionTypes.QuotePaperworkTemplates)
        });
        childItems.push({
            id: 'clientInvoiceTemplates',
            textAsync: this.translateService.stream('tp-client-invoice', { defaultText: 'Client Invoice' }) as Observable<string>,
            url: '/pm/admin/paperwork-templates/clientInvoices',
            data: { title: 'Client Invoice Templates', pageType: PageTypes.ClientInvoiceTemplates, icon: 'tp-templates.png' },
            hidden: !this.persmissionHelper.ValidatePermission(PermissionTypes.InvoiceTemplates)
        });
        childItems.push({
            id: 'purchaseOrderTemplates',
            textAsync: this.translateService.stream('tp-po', { defaultText: 'PO' }) as Observable<string>,
            url: '/pm/admin/paperwork-templates/pos',
            data: { title: 'Purchase Order Templates', pageType: PageTypes.PurchaseOrderTemplates, icon: 'tp-templates.png' },
            hidden: !this.persmissionHelper.ValidatePermission(PermissionTypes.POTemplates)
        });
        childItems.push({
            id: 'resourceInvoiceTemplates',
            textAsync: this.translateService.stream('tp-resource-invoice', { defaultText: 'Resource Invoice' }) as Observable<string>,
            url: '/pm/admin/paperwork-templates/resourceInvoices',
            data: { title: 'Resource Invoice Templates', pageType: PageTypes.ResourceInvoiceTemplates, icon: 'tp-templates.png' },
            hidden: !this.persmissionHelper.ValidatePermission(PermissionTypes.InvoiceTemplates)
        });
        childItems.push({
            id: 'quoteTemplate',
            textAsync: this.translateService.stream('tp-quote', { defaultText: 'Quote' }) as Observable<string>,
            url: '/pm/admin/paperwork-templates/quote/:id/edit',
            data: { title: 'Quote Templates', pageType: PageTypes.QuotePaperworkTemplates, icon: 'tp-templates.png' },
            hidden: true,
        });
        childItems.push({
            id: 'clientInvoiceTemplate',
            textAsync: this.translateService.stream('tp-client-invoice', { defaultText: 'Client Invoice' }) as Observable<string>,
            url: '/pm/admin/paperwork-templates/clientInvoice/:id/edit',
            data: { title: 'Client Invoice Template', pageType: PageTypes.ClientInvoiceTemplates, icon: 'tp-templates.png' },
            hidden: true,
        });
        childItems.push({
            id: 'resourceInvoiceTemplate',
            textAsync: this.translateService.stream('tp-resource-invoice', { defaultText: 'Resource Invoice' }) as Observable<string>,
            url: '/pm/admin/paperwork-templates/resourceInvoice/:id/edit',
            data: { title: 'Resource Invoice Template', pageType: PageTypes.ResourceInvoiceTemplates, icon: 'tp-templates.png' },
            hidden: true,
        });
        childItems.push({
            id: 'purchaseOrderTemplate',
            textAsync: this.translateService.stream('tp-po', { defaultText: 'PO' }) as Observable<string>,
            url: '/pm/admin/paperwork-templates/po/:id/edit',
            data: { title: 'Purchase Order Template', pageType: PageTypes.PurchaseOrderTemplates, icon: 'tp-templates.png' },
            hidden: true,
        });
        return childItems;
    }
    private _buildCatCountTab(menuItems: SidenavMenuItem[]): void {
        menuItems.push({
            id: 'catCount',
            textAsync: this.translateService.stream('tp-cat-count', { defaultText: 'CAT Count' }) as Observable<string>,
            url: '/pm/admin/cat-count-discounts',
            data: { title: 'CAT Count Discounts', pageType: PageTypes.CatCountDiscounts },
            items: this._buildCatCountTabChildItems(),
            hidden: !this.persmissionHelper.ValidatePermissions([PermissionTypes.CATCountDiscounts, PermissionTypes.CATToolSchemas])
        });
    }

    private _buildLogTab(menuItems: SidenavMenuItem[]): void {
        menuItems.push({
            id: 'logTab',
            textAsync: this.translateService.stream('tp-logs', { defaultText: 'Logs' }) as Observable<string>,
            url: '/pm/admin/email-logs',
            data: { title: 'Email Logs', pageType: PageTypes.EmailLogs },
            items: this._buildLogTabChildItems(),
        });
    }
    private _buildLogTabChildItems(): SidenavMenuItem[] {
        const childItems: SidenavMenuItem[] = [];
        childItems.push({
            id: 'emailLog',
            textAsync: this.translateService.stream('tp-email-log', { defaultText: 'Email Logs' }) as Observable<string>,
            url: '/pm/admin/email-logs',
            data: { title: 'Email Logs', pageType: PageTypes.EmailLogs },
            items: null
        });
        childItems.push({
            id: 'activityLogs',
            textAsync: this.translateService.stream('tp-activity-logs', { defaultText: 'Activity logs' }) as Observable<string>,
            url: '/pm/admin/activity-logs',
            data: { title: 'Activity logs', pageType: PageTypes.ClientInvoiceTemplates, icon: 'tp-templates.png' },
            hidden: !this.persmissionHelper.ValidatePermission(PermissionTypes.InvoiceTemplates)
        });
        return childItems;
    }
    private _buildCatCountTabChildItems(): SidenavMenuItem[] {
        const childItems: SidenavMenuItem[] = [];
        childItems.push({
            id: 'catCount',
            textAsync: this.translateService.stream('tp-discounts', { defaultText: 'Discounts' }) as Observable<string>,
            url: '/pm/admin/cat-count-discounts',
            data: { title: 'CAT Count Discounts', pageType: PageTypes.CatCountDiscounts },
            hidden: !this.persmissionHelper.ValidatePermission(PermissionTypes.CATCountDiscounts)
        });

        childItems.push({
            id: 'catCountSchemas',
            textAsync: this.translateService.stream('tp-schemas', { defaultText: 'Schemas' }) as Observable<string>,
            url: '/pm/admin/cat-count-schemas',
            data: { title: 'CAT Count Schemas', pageType: PageTypes.CatCountSchemas },
            hidden: !this.persmissionHelper.ValidatePermission(PermissionTypes.CATToolSchemas)
        });
        return childItems;
    }
    private _buildNotificationsTab(menuItems: SidenavMenuItem[]): void {
        menuItems.push({
            id: 'notifications',
            textAsync: this.translateService.stream('tp-notifications', { defaultText: 'Notifications' }) as Observable<string>,
            data: { title: 'Notifications', pageType: PageTypes.EmailTemplates },
            items: this._buildNotificationsChildItems(),
            hidden: !this.persmissionHelper.ValidatePermissions([PermissionTypes.EmailTemplates])
        });
    }
    private _buildNotificationsChildItems(): SidenavMenuItem[] {
        const childItems: SidenavMenuItem[] = [];
        childItems.push({
            id: 'emailTemplates',
            textAsync: this.translateService.stream('tp-email-templates', { defaultText: 'Email Templates' }) as Observable<string>,
            url: '/pm/admin/email-templates',
            data: { title: 'Email Templates', pageType: PageTypes.EmailTemplates },
            hidden: !this.persmissionHelper.ValidatePermission(PermissionTypes.EmailTemplates)
        });
        childItems.push({
            id: 'emailTemplateType',
            url: '/pm/admin/email-templates/:id/edit',
            data: { title: 'Email Template Type', pageType: PageTypes.EmailTemplateType },
            iconCss: 'ion ion-md-flask',
            textAsync: this.translateService.stream('tp-email-template-type', { defaultText: 'Email Template Type' }) as Observable<string>,
            hidden: true
        });
        return childItems;
    }
    private _buildPortalWidgetsTab(menuItems: SidenavMenuItem[]): void {
        menuItems.push({
            id: 'clientPortal',
            textAsync: this.translateService.stream('tp-client-portal', { defaultText: 'Client Portal' }) as Observable<string>,
            data: { title: 'Portal Portal', pageType: PageTypes.ClientPortal },
            items: this._buildPortalWidgetsChildItems(),
            hidden: false
        });
    }
    private _buildPortalWidgetsChildItems(): SidenavMenuItem[] {
        const childItems: SidenavMenuItem[] = [];
        childItems.push({
            id: 'clientPortalControls',
            textAsync: this.translateService.stream('tp-client-portal-fields', { defaultText: 'Client Portal Fields' }) as Observable<string>,
            url: '/pm/admin/client-portal-fields',
            data: { title: 'Client Portal Fields', pageType: PageTypes.ClientPortalWidgets },
            hidden: false
        });
        return childItems;
    }
    private _buildManagerProjectSettingsTab(menuItems: SidenavMenuItem[]): void {
        menuItems.push({
            id: 'projectSettings',
            textAsync: this.translateService.stream('tp-project-options', { defaultText: 'Project Options' }) as Observable<string>,
            url: '/pm/admin/checklists',
            data: { title: 'Checklists', pageType: PageTypes.Checklists, icon: 'tp-settings.png' },
            items: this._buildManagerProjectSettingsTabChildItems(),
            hidden: !this.persmissionHelper.ValidatePermissions([PermissionTypes.Checklists, PermissionTypes.Feedback, PermissionTypes.Teams])
        });
    }
    private _buildManagerProjectSettingsTabChildItems(): SidenavMenuItem[] {
        const childItems: SidenavMenuItem[] = [];
        childItems.push({
            id: 'checklists',
            textAsync: this.translateService.stream('tp-checklists', { defaultText: 'Checklists' }) as Observable<string>,
            url: '/pm/admin/checklists',
            data: { title: 'Checklists', pageType: PageTypes.Checklists },
            hidden: !this.persmissionHelper.ValidatePermission(PermissionTypes.Checklists)
        });
        childItems.push({
            id: 'feedback',
            textAsync: this.translateService.stream('tp-feedback', { defaultText: 'Feedback' }) as Observable<string>,
            url: '/pm/admin/rating-criteria',
            data: { title: 'Feedback', pageType: PageTypes.Feedback },
            hidden: !this.persmissionHelper.ValidatePermission(PermissionTypes.Feedback),
        });
        return childItems;
    }

    private _buildMigrationsTab(menuItems: SidenavMenuItem[]): void {
        menuItems.push({
            id: 'migrations',
            textAsync: this.translateService.stream('tp-migrations', { defaultText: 'Migrations' }) as Observable<string>,
            url: '/pm/admin/migrations',
            data: { title: 'Migrations', pageType: PageTypes.Migrations },
            items: null,
            //hidden: !this.persmissionHelper.ValidatePermissions([PermissionTypes.CATCountDiscounts, PermissionTypes.CATToolSchemas])
        });
    }

    private _buildAdminTab(menuItems: SidenavMenuItem[], hidden: boolean = true): void {
        if (this.persmissionHelper.ValidatePermission(PermissionTypes.DisplayAdminTab)) {
            const childItems: SidenavMenuItem[] = [];
            this._buildManagerUsersTab(childItems);
            this._buildManagerSystemSettingsTab(childItems);
            this._buildManagerProjectSettingsTab(childItems);
            this._buildManagerCompanyTab(childItems);
            this._buildManagerTemplatesTab(childItems);
            this._buildNotificationsTab(childItems);
            this._buildPortalWidgetsTab(childItems);
            this._buildCatCountTab(childItems);
            this._buildLogTab(childItems);
            this._buildMigrationsTab(childItems);
            menuItems.push(this._buildAdminItem(hidden, childItems));
        }
    }
    public _buildAdminItem(hidden: boolean = true, items: SidenavMenuItem[] = []): SidenavMenuItem {
        return {
            id: 'admin',
            url: '/pm/admin',
            data: { title: 'Admin', pageType: PageTypes.Admin, icon: 'tp-settings.png' },
            iconCss: 'ion ion-md-settings',
            textAsync: this.translateService.stream('tp-admin', { defaultText: 'Admin' }) as Observable<string>,
            items,
            hidden
        };
    }

    private _buildClientRequestsTab(menuItems: SidenavMenuItem[]): void {
        menuItems.push({
            id: 'requests',
            iconCss: 'ion ion-md-folder',
            textAsync: this.translateService.stream('tp-requests-name', { defaultText: 'Requests' }) as Observable<string>,
            url: '/client/requests',
            data: { title: 'Requests', pageType: PageTypes.ClientRequests, icon: 'tp-requests.png' },
        });

    }
    private _buildClientProfileTab(menuItems: SidenavMenuItem[]): void {
        menuItems.push({
            id: 'profile',
            iconCss: 'ion ion-md-folder',
            textAsync: this.translateService.stream('tp-profile', { defaultText: 'Profile' }) as Observable<string>,
            url: '/client/profile',
            data: { title: 'Profile', pageType: PageTypes.ClientProfile, icon: 'tp-clients.png' },
        });
    }

    private _buildClientQuotesTab(menuItems: SidenavMenuItem[]): void {
        menuItems.push({
            id: 'quotes',
            iconCss: 'ion ion-md-folder',
            textAsync: this.translateService.stream('tp-quotes-name', { defaultText: 'Quotes' }) as Observable<string>,
            url: '/client/quotes',
            data: { title: 'Quotes', pageType: PageTypes.ClientQuotes, icon: 'tp-quotes.png' },
            hidden: !this.persmissionHelper.ValidatePermission(PermissionTypes.CPViewQuotes)
        });
    }

    private _buildClientProjectsTab(menuItems: SidenavMenuItem[]): void {
        menuItems.push({
            id: 'projects',
            iconCss: 'ion ion-md-folder',
            textAsync: this.translateService.stream('tp-projects', { defaultText: 'Projects' }) as Observable<string>,
            url: '/client/projects',
            data: { title: 'Projects', pageType: PageTypes.ClientProjects, icon: 'tp-projects.png' },
        });
    }

    private _buildClientInvoicesTab(menuItems: SidenavMenuItem[]): void {
        menuItems.push({
            id: 'invoices',
            iconCss: 'ion ion-md-document',
            textAsync: this.translateService.stream('tp-invoices', { defaultText: 'Invoices' }) as Observable<string>,
            url: '/client/invoices',
            data: { title: 'Invoices', pageType: PageTypes.ClientInvoices, icon: 'tp-invoices.png' },
            hidden: !this.persmissionHelper.ValidatePermission(PermissionTypes.CPViewInvoices)
        });
    }
    private _buildClientCreditsTab(menuItems: SidenavMenuItem[]): void {
        menuItems.push({
            id: 'credits',
            iconCss: 'ion ion-md-document',
            textAsync: this.translateService.stream('tp-credits', { defaultText: 'Credits' }) as Observable<string>,
            url: '/client/credits',
            data: { title: 'Credits', pageType: PageTypes.ClientCredits, icon: 'tp-payments.png' },
            hidden: !this.persmissionHelper.ValidatePermission(PermissionTypes.CPViewStatements)
        });
    }

    private _buildClientPaymentsTab(menuItems: SidenavMenuItem[]): void {
        menuItems.push({
            id: 'payments',
            iconCss: 'payment',
            textAsync: this.translateService.stream('tp-payments', { defaultText: 'Payments' }) as Observable<string>,
            url: '/client/payments',
            data: { title: 'Payments', pageType: PageTypes.ClientPayments, icon: 'tp-payments.png' },
        });
    }

    private _buildClientReportsTab(menuItems: SidenavMenuItem[]): void {
        const childItems: SidenavMenuItem[] = [];

        childItems.push({
            id: 'detailsReport',
            textAsync: this.translateService.stream('tp-details', { defaultText: 'Details' }) as Observable<string>,
            url: '/client/reports/details',
            data: { title: 'Reports', pageType: PageTypes.ClientReports, icon: 'tp-reports.png' },
        });

        childItems.push({
            id: 'revenueReport',
            textAsync: this.translateService.stream('tp-revenue', { defaultText: 'Revenue' }) as Observable<string>,
            url: '/client/reports/sales',
            data: { title: 'Revenue Reports', pageType: PageTypes.ClientRevenueReports, icon: 'tp-reports.png' },
        });

        childItems.push({
            id: 'profitReport',
            textAsync: this.translateService.stream('tp-profit', { defaultText: 'Profit' }) as Observable<string>,
            url: '/client/reports/profit',
            data: { title: 'Profit Reports', pageType: PageTypes.ClientProfitReports, icon: 'tp-reports.png' },
        });

        menuItems.push({
            id: 'reports',
            iconCss: 'ion ion-md-bar-chart',
            textAsync: this.translateService.stream('tp-reports', { defaultText: 'Reports' }) as Observable<string>,
            url: '/client/reports/details',
            data: { title: 'Reports', pageType: PageTypes.ClientReports, icon: 'tp-reports.png' },
            items: childItems,
        });
    }

    private _buildClientFileManagerTab(menuItems: SidenavMenuItem[]): void {
        menuItems.push({
            id: 'fileManager',
            iconCss: 'ion ion-md-folder',
            textAsync: this.translateService.stream('tp-file-manager', { defaultText: 'File Manager' }) as Observable<string>,
            url: '/fileManager/fileManager',
            data: { title: 'File Manager', pageType: PageTypes.ClientFileManager },
        });
    }
}